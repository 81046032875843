import { BaseModel } from 'app/main/shared/models/base.model';

export class Conselho extends BaseModel {
    constructor(
        public id?: number,
        public descricao?: string,
        public sigla?: string
    ) {
        super();
    }

    static fromJson(json: any): Conselho {
        if (json === undefined || json === null){
            return null;
        }
        return new Conselho(json.id, json.descricao, json.sigla);
    }
}
