import {Contato} from './../../../pages/contato/contatos.model';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {EmailService} from 'app/main/pages/emails/email.service';
import {Email} from 'app/main/pages/emails/email.model';
import {MatDialog} from '@angular/material';
import {ConfirmarExclusaoDialogComponent} from '../confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import {PessoaFisica} from 'app/main/pages/pessoas-fisicas/pessoa-fisica.model';
import {BaseEnum} from '../../models/base.enum ';
import {ActivatedRoute} from '@angular/router';
import { SnackBarService } from '../../services/snack-bar/snack-bar.service';

@Component({
    selector: 'app-email',
    templateUrl: './email.component.html',
})
export class EmailComponent implements OnInit {
    @Input() tipoPessoa: string;
    @Input() contato: Contato;
    @Output() emailFormValidate = new EventEmitter();

    emailForm: FormGroup;
    emailsColumns = ['tipo', 'email', 'ativo'];
    emails: Email[] = [];
    pessoaFisica: PessoaFisica;

    constructor(
        protected emailService: EmailService,
        protected formBuilder: FormBuilder,
        protected snackBarService: SnackBarService,
        protected dialog: MatDialog,
        protected activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this.buildEmailForm();
        this.loadEmails();
    }

    public buildEmailForm(): void {
        this.emailForm = this.formBuilder.group({
            id: [null],
            email: [
                null,
                [
                    Validators.required,
                    Validators.maxLength(100),
                    Validators.email
                ]
            ],
            tipo: [null, [Validators.required]],
            contato: [null]
        });
    }

    public loadEmails(): void {
        this.emailService
            .getAllByContatoId(this.contato.id)
            .subscribe(emails => {
                this.emails = emails;
                if (this.emails.length > 0) {
                    this.emailFormValidate.emit('VALID');
                } else {
                    this.emailFormValidate.emit('NO_VALID');
                }
            });
    }

    public containEmail(): boolean {
        if (this.emailForm.get('email').value === 'PRINCIPAL' && this.emails.some(email => email.tipo === 'PRINCIPAL')) {
            this.snackBarService.showAlert('E-mail principal já adicionado.');
            return true;
        }
        if (this.emails.some(email => email.email === this.emailForm.get('email').value)) {
            this.snackBarService.showAlert('E-mail já adicionado.');
            return true;
        }
        return false;
    }

    get containEmailPrincipal(): boolean {
        return this.emails.some(email => email.tipo === 'PRINCIPAL');
    }

    public addEmail(): void {
        this.emailForm.patchValue({contato: this.contato});
        if (this.emailForm.get('id').value == null) {
            if (this.containEmail()) {
                return;
            }
            this.emailService.create(this.emailForm.value).subscribe(
                () => {
                    this.snackBarService.showSuccess('E-mail adicionado com sucesso!');
                    this.loadEmails();
                },
                (e) => this.snackBarService.showError('Erro ao adicionar e-mail.',e)
            );
        } else {
            this.emailService.update(this.emailForm.value).subscribe(
                () => {
                    this.snackBarService.showSuccess('E-mail atualizado com sucesso!');
                    this.loadEmails();
                },
                (e) => this.snackBarService.showError('Erro ao alterar e-mail.',e)
            );
        }
        this.emailForm.reset();
    }

    public editEmail(email: Email): void {
        this.emailService.getById(email.id).subscribe(e => {
            this.emailForm.patchValue(Email.fromJson(e));
        });
    }

    public removeEmailDialog(email: Email): void {
        if (this.emails.length < 2) {
            this.snackBarService.showAlert('Obrigatório possuir pelo menos um email!');
            return;
        } else {
            const dialogRef = this.dialog.open(
                ConfirmarExclusaoDialogComponent,
                {
                    width: '300px',
                    data: {label: email.email}
                }
            );

            dialogRef.afterClosed().subscribe(result => {
                if (!!result) {
                    this.removeEmail(email);
                }
            });
        }
    }

    public removeEmail(email: Email): void {
        this.emailService.removeEmail(email).subscribe(
            () => {
                this.snackBarService.showSuccess('E-mail excluído com sucesso!');
                this.loadEmails();
            },
            (e) => this.snackBarService.showError('Erro ao excluir e-mail.',e)
        );
        this.cleanForm();
    }

    public cleanForm(): void {
        this.emailForm.reset();
    }

    public get hasEmails(): boolean {
        return typeof this.emails !== 'undefined' && this.emails.length > 0;
    }

    get tiposEmails(): BaseEnum[] {
        return this.tipoPessoa == 'PF' ? Email.tiposEmailsPF : Email.tiposEmailsPJ;
    }

    public get isSomenteVisualizacao(): boolean {
        return this.activatedRoute.snapshot.url[1].path == 'detail';
    }

    disableTipoEmail(tipo: string): boolean {
        if (this.tipoPessoa == 'PF' && this.containEmailPrincipal && tipo == 'PRINCIPAL') {
            return true;
        }
        if (this.tipoPessoa == 'PJ' && tipo == 'PRINCIPAL') {
            return this.containEmailPrincipal;
        }
        return false;
    }
}
