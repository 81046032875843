import {HttpParams} from '@angular/common/http';
import {Component, Injector} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {UtilService} from 'app/main/core/services/util.service';
import {PessoaJuridica} from 'app/main/pages/pessoa-juridica/pessoa-juridica.model';
import {PessoaJuridicaService} from 'app/main/pages/pessoa-juridica/pessoa-juridica.service';
import {BaseListComponent} from 'app/main/shared/components/base-list/base-list.component';

@Component({
    selector: 'app-pessoa-juridica-list',
    templateUrl: './pessoa-juridica-list.component.html',
    animations: fuseAnimations
})
export class PessoaJuridicaListComponent extends BaseListComponent<PessoaJuridica> {

    descricao = '';
    displayedColumns =  ['id', 'cnpj', 'razaoSocial', 'nomeFantasia', 'porteEmpresa', 'dataConstituicao', 'ativo']

    constructor(
        injector: Injector,
        service: PessoaJuridicaService,
        public utilService: UtilService
    ) {
        super(
            injector,
            [
                'id',
                'cnpj',
                'razaoSocial',
                'nomeFantasia',
                'dataConstituicao',
                'porteEmpresa',
                'buttons'
            ],
            service
        );
    }

    fillFilters(httpParams: HttpParams): any {
        if (httpParams == null) {
            httpParams = new HttpParams();
        }

        httpParams = httpParams.append('descricao', this.descricao);
        return httpParams;
    }
}
