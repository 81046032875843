import {BaseModel} from 'app/main/shared/models/base.model';
import {SearchableModel} from '../../shared/components/select-search/searchable-model';


export class Cnae extends BaseModel implements SearchableModel {

    constructor(
        public id?: number,
        public codigo?: string,
        public descricao?: string
    ) {
        super();
    }

    static fromJson(json: any): Cnae {
        if (json === undefined || json === null) {
            return null;
        }
        return new Cnae(
            json.id,
            json.codigo,
            json.descricao
        );
    }

    static compareWith = (a: Cnae, b: Cnae): boolean => a && b ? a.id === b.id : a === b;

    searchValue(): string {
        return `${this.codigo}, ${this.descricao}`;
    }

    itemLabel(): string {
        return `${this.codigo} - ${this.descricao}`;
    }
}
