import { Injectable, Injector } from '@angular/core';
import { BaseService } from 'app/main/shared/services/base.service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Email } from './email.model';

@Injectable({
    providedIn: 'root'
})
export class EmailService extends BaseService<Email> {
    constructor(protected injector: Injector) {
        super('/api/emails', injector, Email.prototype, Email.fromJson);
    }

    getAllByContatoId(contatoId: number): Observable<Email[]> {
        const url = `${this.urlResource}/contato/${contatoId}`;

        return this.http
            .get(url)
            .pipe(
                map(response => this.jsonToResources(response)),
                catchError(this.handleError.bind(this))
            );
    }

    removeEmail(email: Email): Observable<any> {
        const url = `${this.urlResource}/${email.id}`;
        return this.http.
            delete(url).
            pipe(
                map(() => null),
                catchError(this.handleError.bind(this))
            );
    }

}
