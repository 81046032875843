import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Cnae} from './cnae.model';


@Injectable({
    providedIn: 'root'
})
export class CnaeService extends BaseService<Cnae> {
    constructor(protected injector: Injector) {
        super('/api/cnaes', injector, Cnae.prototype, Cnae.fromJson);
    }

    getAllCnaes(): Observable<Cnae[]> {
        const url = `${this.urlResource}`;

        return this.http
            .get(url)
            .pipe(
                map(response => this.jsonToResources(response)),
                catchError(this.handleError.bind(this))
            );
    }
}
