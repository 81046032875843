import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [
    {
        id: "applications",
        title: "Dashboards",
        translate: "NAV.APPLICATIONS",
        type: "group",
        icon: "dashboard",
        children: [
            {
                id: "analytics",
                title: "Estatísticas",
                type: "item",
                icon: "timeline",
                url: "/apps/dashboards/analytics"
            },
            {
                id: "project",
                title: "Módulos",
                type: "item",
                icon: "bar_chart",
                url: "/apps/dashboards/project"
            }
        ]
    },
    {
        id: "empreendedor",
        title: "Empreendedor",
        type: "group",
        icon: "business",
        children: [
            {
                id: "empreendedor-empreendimentos",
                title: "Empreendimentos",
                //                        translate: 'NAV.ATIVIDADES.TITLE',
                type: "item",
                icon: "account_balance",
                url: "/shared/empreendedor/empreendimentos"
                //    badge    : {
                //        title    : '33',
                //        translate: 'NAV.ATIVIDADES.BADGE',
                //        bg       : '#F44336',
                //        fg       : '#FFFFFF'
                //    }
            },
            {
                id: "empreendedor-empresas",
                title: "Empresas",
                //                        translate: 'NAV.ATIVIDADES.TITLE',
                type: "item",
                icon: "home",
                url: "/scorp-server/empreendedor/empresas"
                //    badge    : {
                //        title    : '33',
                //        translate: 'NAV.ATIVIDADES.BADGE',
                //        bg       : '#F44336',
                //        fg       : '#FFFFFF'
                //    }
            },
            {
                id: "empreendedor-participantes",
                title: "Participantes (Pessoas)",
                //                        translate: 'NAV.ATIVIDADES.TITLE',
                type: "item",
                icon: "group",
                url: "/scorp-server/empreendedor/pessoas"
                //    badge    : {
                //        title    : '33',
                //        translate: 'NAV.ATIVIDADES.BADGE',
                //        bg       : '#F44336',
                //        fg       : '#FFFFFF'
                //    }
            },
            {
                id: "empreendedor-solicitacoes",
                title: "Solicitações",
                //                        translate: 'NAV.ATIVIDADES.TITLE',
                type: "item",
                icon: "done_all",
                url: "/scorp-server/empreendedor/solicitacoes"
                //    badge    : {
                //        title    : '33',
                //        translate: 'NAV.ATIVIDADES.BADGE',
                //        bg       : '#F44336',
                //        fg       : '#FFFFFF'
                //    }
            },
            {
                id: "empreendedor-atividades",
                title: "Atividades Disponiveis",
                translate: "NAV.ATIVIDADES.TITLE",
                type: "item",
                icon: "gavel",
                url: "/scorp-server/empreendedor/atividades"
                //                        badge    : {
                //                            title    : '33',
                //                            translate: 'NAV.ATIVIDADES.BADGE',
                //                            bg       : '#F44336',
                //                            fg       : '#FFFFFF'
                //                        }
            },
            {
                id: "empreendedor-formularios",
                title: "Formulários",
                translate: "NAV.ATIVIDADES.TITLE",
                type: "item",
                icon: "list_alt",
                url: "/scorp-server/empreendedor/formularios"
                //                        badge    : {
                //                            title    : '33',
                //                            translate: 'NAV.ATIVIDADES.BADGE',
                //                            bg       : '#F44336',
                //                            fg       : '#FFFFFF'
                //                        }
            }
        ]
    },
    {
        id: "resp-tecnico",
        title: "Responsavel Técnico",
        type: "group",
        icon: "supervised_user_circle",
        children: [
            {
                id: "resptecnico-empreendimentos",
                title: "Meu Cadastro",
                //    translate: 'NAV.ATIVIDADES.TITLE',
                type: "item",
                icon: "portrait",
                url: "/scorp-server/rt/cadastro"
                //    badge    : {
                //        title    : '33',
                //        translate: 'NAV.ATIVIDADES.BADGE',
                //        bg       : '#F44336',
                //        fg       : '#FFFFFF'
                //    }
            },
            {
                id: "resptecnico-empreendimentos",
                title: "Empreendimentos",
                //    translate: 'NAV.ATIVIDADES.TITLE',
                type: "item",
                icon: "account_balance",
                url: "/scorp-server/rt/emmpreendimentos"
                //    badge    : {
                //        title    : '33',
                //        translate: 'NAV.ATIVIDADES.BADGE',
                //        bg       : '#F44336',
                //        fg       : '#FFFFFF'
                //    }
            },
            {
                id: "resptecnico-pendencias",
                title: "Pendências",
                //    translate: 'NAV.ATIVIDADES.TITLE',
                type: "item",
                icon: "priority_high",
                url: "/scorp-server/rt/pendencias"
                //    badge    : {
                //        title    : '33',
                //        translate: 'NAV.ATIVIDADES.BADGE',
                //        bg       : '#F44336',
                //        fg       : '#FFFFFF'
                //    }
            },
            {
                id: "resptecnico-atividades",
                title: "Atividades Disponiveis",
                translate: "NAV.ATIVIDADES.TITLE",
                type: "item",
                icon: "gavel",
                url: "/scorp-server/rt/atividades"
                //                            badge    : {
                //                                title    : '33',
                //                                translate: 'NAV.ATIVIDADES.BADGE',
                //                                bg       : '#F44336',
                //                                fg       : '#FFFFFF'
                //                            }
            },
            {
                id: "resptecnico-participantes",
                title: "Participantes (Pessoas)",
                //                        translate: 'NAV.ATIVIDADES.TITLE',
                type: "item",
                icon: "group",
                url: "/scorp-server/rt/pessoas"
                //    badge    : {
                //        title    : '33',
                //        translate: 'NAV.ATIVIDADES.BADGE',
                //        bg       : '#F44336',
                //        fg       : '#FFFFFF'
                //    }
            },
            {
                id: "resptecnico-formularios",
                title: "Formulários",
                translate: "NAV.ATIVIDADES.TITLE",
                type: "item",
                icon: "list_alt",
                url: "/scorp-server/rt/formularios"
                //                        badge    : {
                //                            title    : '33',
                //                            translate: 'NAV.ATIVIDADES.BADGE',
                //                            bg       : '#F44336',
                //                            fg       : '#FFFFFF'
                //                        }
            }
        ]
    },
    {
        id: "analistaambiental",
        title: "Analista Ambiental",
        type: "group",
        icon: "assignment_turned_in",
        children: [
            {
                id: "analistaambiental-processos",
                title: "Meus Processos",
                //                        translate: 'NAV.ATIVIDADES.TITLE',
                type: "item",
                icon: "library_books",
                url: "/scorp-server/analise/processos"
                //    badge    : {
                //        title    : '33',
                //        translate: 'NAV.ATIVIDADES.BADGE',
                //        bg       : '#F44336',
                //        fg       : '#FFFFFF'
                //    }
            },
            {
                id: "analistaambiental-processos",
                title: "Solicitações (admin)",
                //                        translate: 'NAV.ATIVIDADES.TITLE',
                type: "item",
                icon: "folder_shared",
                url: "/scorp-server/analise/solicitacoes"
                //    badge    : {
                //        title    : '33',
                //        translate: 'NAV.ATIVIDADES.BADGE',
                //        bg       : '#F44336',
                //        fg       : '#FFFFFF'
                //    }
            },
            {
                id: "analistaambiental-processos",
                title: "Participantes (admin)",
                //                      translate: 'NAV.ATIVIDADES.TITLE',
                type: "item",
                icon: "group",
                url: "/scorp-server/analise/pessoas"
                //    badge    : {
                //        title    : '33',
                //        translate: 'NAV.ATIVIDADES.BADGE',
                //        bg       : '#F44336',
                //        fg       : '#FFFFFF'
                //    }
            }
        ]
    },
    {
        id: "atendimento",
        title: "Atendimento",
        type: "group",
        icon: "phone_callback",
        children: [
            {
                id: "atendimento-processos",
                title: "Consulta Processos",
                //    translate: 'NAV.ATIVIDADES.TITLE',
                type: "item",
                icon: "library_books",
                url: "/scorp-server/atendimento/processos"
                //    badge    : {
                //        title    : '33',
                //        translate: 'NAV.ATIVIDADES.BADGE',
                //        bg       : '#F44336',
                //        fg       : '#FFFFFF'
                //    }
            },
            {
                id: "atendimento-fale",
                title: "Fale Conosco",
                //    translate: 'NAV.ATIVIDADES.TITLE',
                type: "item",
                icon: "phone_callback",
                url: "/scorp-server/atendimento/fale-conosco"
                //    badge    : {
                //        title    : '33',
                //        translate: 'NAV.ATIVIDADES.BADGE',
                //        bg       : '#F44336',
                //        fg       : '#FFFFFF'
                //    }
            },
            {
                id: "atendimento-usuarios",
                title: "Usuários (senhas, bloqueios)",
                //    translate: 'NAV.ATIVIDADES.TITLE',
                type: "item",
                icon: "sentiment_satisfied_alt",
                url: "/scorp-server/atendimento/usuarios"
                //    badge    : {
                //        title    : '33',
                //        translate: 'NAV.ATIVIDADES.BADGE',
                //        bg       : '#F44336',
                //        fg       : '#FFFFFF'
                //    }
            }
        ]
    },
    {
        id: "entidades",
        title: "Entidades Externas",
        type: "group",
        icon: "security",
        children: [
            {
                id: "entidades-processos",
                title: "Consulta Processos",
                //    translate: 'NAV.ATIVIDADES.TITLE',
                type: "item",
                icon: "library_books",
                url: "/scorp-server/entidades/processos"
                //    badge    : {
                //        title    : '33',
                //        translate: 'NAV.ATIVIDADES.BADGE',
                //        bg       : '#F44336',
                //        fg       : '#FFFFFF'
                //    }
            },
            {
                id: "entidades-atividades",
                title: "Atividades Disponiveis",
                translate: "NAV.ATIVIDADES.TITLE",
                type: "item",
                icon: "gavel",
                url: "/scorp-server/entidades/atividades"
                //                        badge    : {
                //                            title    : '33',
                //                            translate: 'NAV.ATIVIDADES.BADGE',
                //                            bg       : '#F44336',
                //                            fg       : '#FFFFFF'
                //                        }
            },
            {
                id: "entidades-pendencias",
                title: "Pendências",
                //    translate: 'NAV.ATIVIDADES.TITLE',
                type: "item",
                icon: "priority_high",
                url: "/scorp-server/entidades/pendencias"
                //    badge    : {
                //        title    : '33',
                //        translate: 'NAV.ATIVIDADES.BADGE',
                //        bg       : '#F44336',
                //        fg       : '#FFFFFF'
                //    }
            },
            {
                id: "entidades-processos",
                title: "Participantes",
                //                  translate: 'NAV.ATIVIDADES.TITLE',
                type: "item",
                icon: "group",
                url: "/scorp-server/pessoas"
                //    badge    : {
                //        title    : '33',
                //        translate: 'NAV.ATIVIDADES.BADGE',
                //        bg       : '#F44336',
                //        fg       : '#FFFFFF'
                //    }
            }
        ]
    },
    {
        id: "transparencia",
        title: "Transparência",
        type: "group",
        icon: "visibility",
        children: [
            {
                id: "transparencia-processos",
                title: "Consulta Processos",
                //    translate: 'NAV.ATIVIDADES.TITLE',
                type: "item",
                icon: "search",
                url: "/scorp-server/transparencia/processos"
                //    badge    : {
                //        title    : '33',
                //        translate: 'NAV.ATIVIDADES.BADGE',
                //        bg       : '#F44336',
                //        fg       : '#FFFFFF'
                //    }
            },
            {
                id: "transparencia-autenticidade",
                title: "Autenticidade de Documentos",
                //    translate: 'NAV.ATIVIDADES.TITLE',
                type: "item",
                icon: "verified_user",
                url: "/scorp-server/transparencia/autenticar"
                //    badge    : {
                //        title    : '33',
                //        translate: 'NAV.ATIVIDADES.BADGE',
                //        bg       : '#F44336',
                //        fg       : '#FFFFFF'
                //    }
            },
            {
                id: "transparencia-simulacao",
                title: "Simulações",
                //    translate: 'NAV.ATIVIDADES.TITLE',
                type: "item",
                icon: "wb_incandescent",
                url: "/scorp-server/transparencia/simular"
                //    badge    : {
                //        title    : '33',
                //        translate: 'NAV.ATIVIDADES.BADGE',
                //        bg       : '#F44336',
                //        fg       : '#FFFFFF'
                //    }
            }
        ]
    },
    {
        id: "cadastros",
        title: "Cadastros e Parametros",
        translate: "NAV.CADASTROS",
        type: "group",
        icon: "settings",
        children: [
            {
                id: "conselhos",
                title: "Conselhos",
                translate: "NAV.CONSELHOS.TITLE",
                type: "item",
                icon: "settings",
                url: "/scorp-server/conselhos",
                badge: {
                    title: "33",
                    translate: "NAV.CONSELHOS.BADGE",
                    bg: "#F44336",
                    fg: "#FFFFFF"
                }
            },
            {
                id: "municipios",
                title: "Municípios",
                translate: "NAV.MUNICIPIO.TITLE",
                type: "item",
                icon: "settings",
                url: "/scorp-server/municipio"
            }
        ]
    }
];
