import { Injectable, Injector } from '@angular/core';
import { BaseService } from 'app/main/shared/services/base.service';
import {Responsavel} from './responsavel.model';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class ResponsavelService extends BaseService<Responsavel> {
    constructor(protected injector: Injector) {
        super('/api/representantes', injector, Responsavel.prototype, Responsavel.fromJson);
    }

    update(responsavel: Responsavel): Observable<Responsavel> {
        return this.http
            .put(this.urlResource, responsavel)
            .pipe(
                map(response => Responsavel.fromJson(response)),
                catchError(this.handleError.bind(this))
            );
    }

    getRepresentantes(idPessoa): Observable<Responsavel[]> {
        const url = `${this.urlResource}/representado/${idPessoa}`;

        return this.http
            .get(url)
            .pipe(
                map(response => this.jsonToResources(response)),
                catchError(this.handleError.bind(this))
            );
    }

    desativaRepresentante(idRepresentado, idRepresentante): Observable<any> {
        const url = `${this.urlResource}/${idRepresentado}/${idRepresentante}`;
        return this.http.
        delete(url).
        pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }

    ativaRepresentante(idRepresentado, idRepresentante): Observable<any> {
        const url = `${this.urlResource}/${idRepresentado}/${idRepresentante}`;
        return this.http.put(url, {}).
        pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }


}

