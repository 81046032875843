import { Injectable, Injector } from '@angular/core';
import { BaseService } from 'app/main/shared/services/base.service';
import { TipoOrganizacaoAdministrativa } from './tipo-organizacao-administrativa.model';

@Injectable({
    providedIn: 'root'
})
export class TipoOrganizacaoAdministrativaService extends BaseService<TipoOrganizacaoAdministrativa> {
    constructor(protected injector: Injector) {
        super('/api/tipos-organizacao-administrativa', injector, TipoOrganizacaoAdministrativa.prototype, TipoOrganizacaoAdministrativa.fromJson);
    }
}
