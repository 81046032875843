import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {fuseAnimations} from '@fuse/animations';
import {BaseDetailComponent} from 'app/main/shared/components/base-detail/base-detail.component';
import {GrupoDocumento} from '../grupo-documento.model';
import {GrupoDocumentoService} from '../grupo-documento.service';
import {GrupoDocumentoTemplateModel} from '../grupo-documento-template.model';
import {ConfirmarExclusaoDialogComponent} from '../../../shared/components/confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import {GrupoDocumentoTemplateService} from '../grupo-documento-template.service';
import {DocumentoTemplateService} from '../../documentos-template/documento-template.service';
import {DocumentoTemplate} from '../../documentos-template/documento-template.model';

@Component({
    selector: 'app-grupo-documento-detail',
    templateUrl: './grupo-documento-detail.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations
})
export class GrupoDocumentoDetailComponent extends BaseDetailComponent<GrupoDocumento> {

    gruposDocumentoTemplate: GrupoDocumentoTemplateModel[];
    documentosTemplate: DocumentoTemplate[];
    grupoDocumentoTemplateFormControl = new FormControl(null, [Validators.required]);
    columns = ['descricaoDocumento', 'tamanhoDocumento', 'tamanhoPagina', 'opcional', 'acoes'];
    compareWith = DocumentoTemplate.compareWith;
    opcional = false;

    constructor(
        protected grupoDocumentoService: GrupoDocumentoService,
        protected grupoDocumentoTemplateService: GrupoDocumentoTemplateService,
        protected documentoTemplateService: DocumentoTemplateService,
        protected injector: Injector
    ) {
        super(
            injector,
            new GrupoDocumento(),
            grupoDocumentoService,
            GrupoDocumento.fromJson
        );
    }

    afterResourceLoad(): void {
        this.loadGruposDocumentoTemplate();
        this.loadDocumentosTemplate();
    }

    protected buildResourceForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            nome: [null, [Validators.required, Validators.maxLength(255)]],
            descricao: [null, [Validators.required, Validators.maxLength(255)]]
        });
    }

    public loadDocumentosTemplate(): void {
        this.documentoTemplateService.getAll()
            .subscribe(documentosTemplate => {
                this.documentosTemplate = documentosTemplate;
            });
    }

    public get mostrarDocumentosTemplates(): boolean {
        return (
            typeof this.gruposDocumentoTemplate !== 'undefined' && this.gruposDocumentoTemplate.length > 0
        );
    }

    addGrupoDocumentoTemplate(): void {
        if (typeof this.grupoDocumentoTemplateFormControl !== 'undefined' && this.grupoDocumentoTemplateFormControl.value !== null) {
            const grupoDocumentoTemplateModel: GrupoDocumentoTemplateModel = {
                grupoId: this.resource.id,
                templateId: this.grupoDocumentoTemplateFormControl.value.id, opcional: this.opcional
            };
            this.grupoDocumentoTemplateService
                .addGrupoDocumentoTemplate(grupoDocumentoTemplateModel)
                .subscribe(
                    () => {
                        this.snackBarService.showSuccess('Documento adicionado com sucesso!');
                        this.loadGruposDocumentoTemplate();
                        this.grupoDocumentoTemplateFormControl.reset();
                        this.opcional = false;
                    },
                    (e) =>
                        this.snackBarService.showError('Erro ao adicionar Documento.', e)
                )   ;
        } else {
            this.snackBarService.showAlert('Selecione o Documento a ser adicionado.');
        }
    }

    public removeDocumentoTemplateDialog(documento: GrupoDocumentoTemplateModel): void {
        this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: {label: documento.descricaoDocumento}
        }).afterClosed().subscribe(result => {
            if (!!result) {
                this.removerDocumento(documento);
            }
        });
    }

    removerDocumento(grupoDocumentoTemplateModel: GrupoDocumentoTemplateModel): void {
         this.grupoDocumentoTemplateService
            .removeGrupoDocumentoTemplate(grupoDocumentoTemplateModel)
            .subscribe(
                () => {
                    this.loadGruposDocumentoTemplate();
                    this.snackBarService.showSuccess('Documento excluído com sucesso!');
                },
                (e) =>
                    this.snackBarService.showError('Erro ao excluir documento.', e)
            );
    }

    public loadGruposDocumentoTemplate(): void {
        this.grupoDocumentoTemplateService.getAllByGrupoDocumento(this.resource)
            .subscribe(grupoDocumentoTemplate => {
                this.gruposDocumentoTemplate = grupoDocumentoTemplate;
            });
    }

    protected creationPageTitle(): string {
        return 'Cadastro de novo grupo de documento';
    }

    protected editionPageTitle(): string {
        const resourceName = this.resource.nome || '';
        return 'Editando o grupo de documento: ' + resourceName;
    }

    protected visualizationPageTitle(): string {
        const resourceName = this.resource.nome || '';
        return 'Visualizando o grupo de documento: ' + resourceName;
    }

}
