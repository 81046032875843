import {NgModule} from '@angular/core';
import {PessoaJuridicaListComponent} from 'app/main/pages/pessoa-juridica/pessoa-juridica-list/pessoa-juridica-list.component';
import {PessoaJuridicaDetailComponent} from 'app/main/pages/pessoa-juridica/pessoa-juridica-detail/pessoa-juridica-detail.component';
import {
    PessoaJuridicaListResolver,
    PessoaJuridicaResolver
} from 'app/main/pages/pessoa-juridica/pessoa-juridica.service';
import {Routes, RouterModule} from '@angular/router';
import {CoreModule} from 'app/main/core/core.module';
import {SharedModule} from 'app/main/shared/shared.module';
import {CoreComponentsModule} from 'app/main/core/components/core-components.module';
import {MatTooltipModule} from "@angular/material/tooltip";


export const routes: Routes = [
    {
        path: 'new',
        component: PessoaJuridicaDetailComponent
    },
    {
        path: 'pessoas-juridicas',
        component: PessoaJuridicaListComponent,
        resolve: {
            data: PessoaJuridicaListResolver
        }
    },
    {
        path: ':id/edit',
        component: PessoaJuridicaDetailComponent,
        resolve: {
            data: PessoaJuridicaResolver
        }
    },
    {
        path: ':id/detail',
        component: PessoaJuridicaDetailComponent,
        resolve: {
            data: PessoaJuridicaResolver
        }
    }
];

@NgModule({
    declarations: [PessoaJuridicaListComponent, PessoaJuridicaDetailComponent],
    imports: [CoreModule.forRoot(), CoreComponentsModule, SharedModule, RouterModule.forChild(routes), MatTooltipModule],
    entryComponents: [PessoaJuridicaDetailComponent, PessoaJuridicaListComponent],
    providers: [
        PessoaJuridicaResolver, PessoaJuridicaListResolver
    ]

})
export class PessoaJuridicaModule {
}
