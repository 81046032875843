import {Injectable, Injector} from '@angular/core';
import {PessoaFisica} from './pessoa-fisica.model';
import {RoutingStateService} from 'app/main/core/services/routing-state.service';
import {BaseService} from 'app/main/shared/services/base.service';
import {ListResolver} from 'app/main/shared/resolvers/list-resolver';
import {DetailResolver} from 'app/main/shared/resolvers/detail-resolver';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class PessoaFisicaListResolver extends ListResolver<PessoaFisica> {
    constructor(service: PessoaFisicaService) {
        super(service);
    }
}

@Injectable()
export class PessoaFisicaResolver extends DetailResolver<PessoaFisica> {
    constructor(
        service: PessoaFisicaService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: 'root'
})
export class PessoaFisicaService extends BaseService<PessoaFisica> {
    constructor(protected injector: Injector) {
        super(
            '/api/pessoas-fisicas',
            injector,
            PessoaFisica.prototype,
            PessoaFisica.fromJson
        );
    }

    getByCpf(cpf: string): Observable<PessoaFisica> {
        const url = `${this.urlResource}/cpf/${cpf}`;

        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    getByCpfAndCadastroCompleto(cpf: string): Observable<PessoaFisica> {
        const url = `${this.urlResource}/cpf/${cpf}/cadastro-completo`;

        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    getByPessoaId(pessoaId: number): Observable<PessoaFisica> {
        const url = `${this.urlResource}/pessoaId/${pessoaId}`;

        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }
}
