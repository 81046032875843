import { Injectable, Injector } from '@angular/core';
import { BaseService } from 'app/main/shared/services/base.service';
import { GestaoOrganizacaoAdministrativa } from './gestao-organizacao-administrativa.model';

@Injectable({
    providedIn: 'root'
})
export class GestaoOrganizacaoAdministrativaService extends BaseService<GestaoOrganizacaoAdministrativa> {
    constructor(protected injector: Injector) {
        super('/api/gestoes-organizacoes-administrativas', injector, GestaoOrganizacaoAdministrativa.prototype, GestaoOrganizacaoAdministrativa.fromJson);
    }
}
