import {EventEmitter, Injectable, Injector, OnDestroy} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import {AuthServerProvider} from './auth-provider.service';
import {UtilService} from './util.service';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {of} from 'rxjs';
import {Constants} from 'app/main/shared/utils/constants';

@Injectable()
export class AccountService implements OnDestroy {
    currentAccount: any;
    public selectedPhoto: Blob;
    public eventEmitter: EventEmitter<any>;
    private authSubscription: any;
    public stateBilheteShow: any = false;

    constructor(
        public httpClient: HttpClient,
        protected injector: Injector,
        private authProvider: AuthServerProvider,
        public util: UtilService
    ) {
        this.eventEmitter = new EventEmitter();

        this.authSubscription = this.authProvider.authEventEmitter.subscribe(
            e => {
                if (e !== undefined && e === 'auth:logout') {
                    this.clearAccount();
                }
                if (e !== undefined && e === 'auth:login') {
                    this.getAccount(true).subscribe(() => {});
                }
            }
        );
    }

    ngOnDestroy(): void {
        if (this.authSubscription != null) {
            this.authSubscription.unsubscribe();
        }
    }

    setAccount(account): Promise<any> {
        if (account != null) {
            // this.storage.store('account', account);
            this.currentAccount = account;
            return Promise.resolve(account);
        }
        return Promise.reject(null);
    }

    getAccountLocal(): Promise<any> {
        // #todo
        return new Promise((resolve, reject) => {});
        // return this.storage.retrieve('account');
    }

    getAccount(forceRefresh?: boolean): Observable<any> {
        const userInfoUrl = Constants.URL_WSO2_USER_INFO;
        if (this.currentAccount != null && !forceRefresh) {
            return of(this.currentAccount);
        }
        if (!forceRefresh) {
            return of(this.getAccountLocal());
        }

        return this.httpClient
            .get(`${userInfoUrl}`, {
                responseType: 'json'
            })
            .pipe(
                map((res: any) => {
                    this.convertItemFromServer(res);
                    this.currentAccount = res;
                    this.setAccount(this.currentAccount);

                    return res;
                })
            );
    }

    save(account: any): Observable<any> {
        const defaultParams = new HttpParams();
        const headers: HttpHeaders = new HttpHeaders();
        headers
            .append('Content-Type', 'application/json')
            .append('Accept', 'application/json');

        account = this.convertToSave(account);

        return this.httpClient
            .post(
                Constants.URL_GATEWAY + '/bm-service-uaa/api/account/update',
                account,
                {
                    responseType: 'json',
                    params: defaultParams,
                    headers: headers
                }
            )
            .pipe(
                map(res => {
                    this.convertItemFromServer(res);
                    this.currentAccount = res;
                    this.setAccount(this.currentAccount);
                    return this.currentAccount;
                })
            );
    }

    clearAccount(): void {
        this.currentAccount = null;
    }

    convertToSave(account): void {
        const copy = Object.assign({}, account);
        if (copy.cadastro && copy.cadastro.pessoaFisica) {
            if (account.cadastro.pessoaFisica.cpf) {
                account.cadastro.registro = account.cadastro.pessoaFisica.cpf;
            }

            copy.cadastro.pessoaFisica.datanascimento = this.util.strToZonedDateTime(
                copy.cadastro.pessoaFisica.datanascimento,
                'DD/MM/YYYY'
            );
        }
        return copy;
    }

    private convertItemFromServer(entity: any): void {
        if (entity.cadastro === undefined || entity.cadastro === null) {
            entity.cadastro = {};
        }
        if (
            entity.cadastro.pessoaFisica === undefined ||
            entity.cadastro.pessoaFisica === null
        ) {
            entity.cadastro.pessoaFisica = {};
        }
        if (entity.cadastro.pessoaFisica.cpf) {
            if (entity.cadastro.pessoaFisica.cpf) {
                entity.cadastro.registro = entity.cadastro.pessoaFisica.cpf;
            }
            entity.cadastro.pessoaFisica.datanascimento = this.util.zonedDateTimeFormat(
                entity.cadastro.pessoaFisica.datanascimento,
                'DD/MM/YYYY'
            );
        }
        return entity;
    }
}
