import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Contato} from 'app/main/pages/contato/contatos.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material';
import {Telefone} from 'app/main/pages/telefones/telefone.model';
import {TelefoneService} from 'app/main/pages/telefones/telefone.service';
import {ConfirmarExclusaoDialogComponent} from '../confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import {PessoaFisica} from 'app/main/pages/pessoas-fisicas/pessoa-fisica.model';
import {BaseEnum} from '../../models/base.enum ';
import {ActivatedRoute} from '@angular/router';
import { SnackBarService } from '../../services/snack-bar/snack-bar.service';

@Component({
    selector: 'app-telefone',
    templateUrl: './telefone.component.html',
})
export class TelefoneComponent implements OnInit {

    @Input() contato: Contato;
    @Output() telefoneFormValidate = new EventEmitter();

    maskCell = '0000-00009';
    telefoneForm: FormGroup;
    telefones: Telefone[];
    telefonesColumns = ['tipo', 'numero', 'ativo'];
    maskPhoneNumber: string;
    pessoaFisica: PessoaFisica;

    constructor(
        protected formBuilder: FormBuilder,
        protected snackBarService: SnackBarService,
        protected dialog: MatDialog,
        protected telefoneService: TelefoneService,
        protected activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit(): any {
        this.buildTelefoneForm();
        this.loadTelefones();
    }

    public buildTelefoneForm(): void {
        this.telefoneForm = this.formBuilder.group({
            id: [null],
            ddd: [null, [Validators.required, Validators.maxLength(4)]],
            ddi: ['55', [Validators.required, Validators.maxLength(4)]],
            tipo: [null, [Validators.required]],
            numero: [
                null,
                [
                    Validators.required,
                    Validators.compose([
                        Validators.required,
                        Validators.minLength(9),
                        Validators.maxLength(10)
                    ])
                ]
            ],
            contato: [null]
        });
    }

    public loadTelefones(): void {
        this.telefoneService
            .getAllByContatoId(this.contato.id)
            .subscribe(telefones => {
                this.telefones = telefones;
                if (this.telefones.length > 0) {
                    this.telefoneFormValidate.emit('VALID');
                } else {
                    this.telefoneFormValidate.emit('NO_VALID');
                }
            });
    }

    public containNumero(): boolean {
        if (
            this.telefones.some(
                telefone =>
                    telefone.numero ===
                    this.telefoneForm
                        .get('numero')
                        .value.match(/\d+/g)
                        .join('')
            )
        ) {
            this.snackBarService.showAlert('Telefone já adicionado.');
            return true;
        }
        return false;
    }

    public addTelefone(): void {
        this.telefoneForm.patchValue({contato: this.contato});
        if (this.telefoneForm.get('id').value == null) {
            if (this.containNumero()) {
                return;
            }
            this.telefoneService
                .create(Telefone.fromJson(this.telefoneForm.value))
                .subscribe(
                    () => {
                        this.snackBarService.showSuccess('Telefone adicionado com sucesso!');
                        this.loadTelefones();
                    },
                    (e) => this.snackBarService.showError('Erro ao adicionar telefone.',e)
                );
        } else {
            this.telefoneService
                .update(Telefone.fromJson(this.telefoneForm.value))
                .subscribe(
                    () => {
                        this.snackBarService.showSuccess('Telefone atualizado com sucesso!');
                        this.loadTelefones();
                    },
                    (e) => this.snackBarService.showError('Erro ao alterar telefone.',e)
                );
        }
        this.cleanForms();
    }

    public editTelefone(telefone: Telefone): void {
        this.telefoneService.getById(telefone.id).subscribe(t => {
            this.telefoneForm.patchValue(Telefone.fromJson(t));
        });
    }

    public removeTelefoneDialog(telefone: Telefone): void {
        if (this.telefones.length < 2) {
            this.snackBarService.showAlert('Obrigatório possuir pelo menos um telefone!');
            return;
        } else {
            const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
                width: '300px',
                data: {label: telefone.numero}
            });

            dialogRef.afterClosed().subscribe(result => {
                if (!!result) {
                    this.removeTelefone(telefone);
                }
            });
        }
    }

    public removeTelefone(telefone: Telefone): void {
        this.telefoneService.removeTelefone(telefone).subscribe(
            () => {
                this.snackBarService.showSuccess('Telefone excluído com sucesso!');
                this.loadTelefones();
            },
            (e) => this.snackBarService.showError('Erro ao excluir telefone.',e)
        );
        this.cleanForms();
    }

    public get hasTelefones(): boolean {
        return (
            typeof this.telefones !== 'undefined' && this.telefones.length > 0
        );
    }

    public cleanForms(): void {
        this.telefoneForm.reset();
    }

    onChangeCelular(): void {
        if (this.telefoneForm.get('numero').value.length >= 10) {
            this.maskCell = '00000-0000';
        } else {
            this.maskCell = '0000-00009';
        }
    }

    get tiposTeleones(): BaseEnum[] {
        return Telefone.tiposTelefones;
    }

    public get isSomenteVisualizacao(): boolean {
        return this.activatedRoute.snapshot.url[1].path == 'detail';
    }
}
