import { BaseModel } from 'app/main/shared/models/base.model';
import { PessoaJuridica } from '../pessoa-juridica/pessoa-juridica.model';

export class Gestao extends BaseModel {
    constructor(
        public id?: number,
        public descricao?: string,
        public nome?: string,
        public pessoaJuridica?: PessoaJuridica
    ) {
        super();
    }

    static fromJson(json: any): Gestao {
        if (json === undefined || json === null) {
            return null;
        }
        return new Gestao(
            json.id,
            json.descricao,
            json.nome,
            PessoaJuridica.fromJson(json.pessoaJuridica)
        );
    }
}
