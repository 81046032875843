import {HttpParams} from '@angular/common/http';
import {Component, Injector, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {BaseListComponent} from 'app/main/shared/components/base-list/base-list.component';
import {PessoaFisica} from '../pessoa-fisica.model';
import {PessoaFisicaService} from '../pessoa-fisica.service';

@Component({
    selector: 'app-pessoa-fisica-list',
    templateUrl: './pessoa-fisica-list.component.html',
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class PessoaFisicaListComponent extends BaseListComponent<PessoaFisica> {

    descricao = '';

    constructor(injector: Injector, service: PessoaFisicaService) {
        super(injector, ['id', 'cpf', 'nome', 'nomeSocial', 'ativo'], service);
    }

    fillFilters(httpParams: HttpParams): any {
        if (httpParams == null) {
            httpParams = new HttpParams();
        }

        httpParams = httpParams.append('descricao', this.descricao);
        return httpParams;
    }
}
