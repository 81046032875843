import {
    ChangeDetectorRef,
    Component,
    Injector,
    ViewEncapsulation
} from '@angular/core';

import { HttpParams } from '@angular/common/http';
import { fuseAnimations } from '@fuse/animations';
import { BaseListComponent } from 'app/main/shared/components/base-list/base-list.component';
import {Taxonomia} from "../taxonomia.model";
import {TaxonomiaService} from "../taxonomia.service";

const displayedColumns: string[] = [
    'id',
    'nomeCientifico',
    'situacaoCancelado',
    'usoSema',
    'ativo'
];

@Component({
    selector: 'app-taxonomia-list',
    templateUrl: './taxonomia-list.component.html',
    styleUrls: ['./taxonomia-list.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class TaxonomiaListComponent extends BaseListComponent<Taxonomia> {
    descricao: string = '';

    constructor(
        injector: Injector,
        service: TaxonomiaService,
        private changeDetectorRefs: ChangeDetectorRef) {
        super(injector, displayedColumns, service);
    }

    fillFilters(httpParams: HttpParams): any {
        if (httpParams == null) {
            httpParams = new HttpParams();
        }

        httpParams = httpParams.append('descricao', this.descricao);

        return httpParams;
    }
}