import {Injectable, Injector} from '@angular/core';
import {GrupoDocumento} from './grupo-documento.model';
import {BaseService} from 'app/main/shared/services/base.service';
import {ListResolver} from '../../shared/resolvers/list-resolver';
import {DetailResolver} from '../../shared/resolvers/detail-resolver';
import {RoutingStateService} from '../../core/services/routing-state.service';

@Injectable()
export class GrupoDocumentoListResolver extends ListResolver<GrupoDocumento> {
    constructor(service: GrupoDocumentoService) {
        super(service);
    }
}

@Injectable()
export class GrupoDocumentoResolver extends DetailResolver<GrupoDocumento> {
    constructor(
        service: GrupoDocumentoService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: 'root'
})
export class GrupoDocumentoService extends BaseService<GrupoDocumento> {
    constructor(protected injector: Injector) {
        super('/api/grupos-documentos', injector, GrupoDocumento.prototype, GrupoDocumento.fromJson);
    }
}
