import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {Cnae} from '../../../pages/cnaes/cnae.model';
import {CnaeService} from '../../../pages/cnaes/cnae.service';
import {ActivatedRoute} from '@angular/router';
import {Pessoa} from '../../../pages/pessoas/pessoa.model';
import {ConfirmarExclusaoDialogComponent} from '../confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {SnackBarService} from '../../services/snack-bar/snack-bar.service';
import {CnaePessoaService} from '../../../pages/cnaes/cnae-pessoa.service';
import {CnaePessoa} from '../../../pages/cnaes/cnae-pessoa.model';


@Component({
    selector: 'app-atividade',
    templateUrl: './atividade.component.html',
    styleUrls: ['./atividade.component.scss']
})
export class AtividadeComponent implements OnInit {

    @Input() pessoa: Pessoa;
    @Output() atividadeFormValidate = new EventEmitter();

    compareWith = Cnae.compareWith;
    cnaes: Cnae[];
    cnaesPessoa: CnaePessoa[];
    cnaeColumns = ['codigo', 'descricao', 'ativo'];
    cnaeFormControl = new FormControl(null, [Validators.required]);

    constructor(
        protected formBuilder: FormBuilder,
        protected cnaeService: CnaeService,
        protected cnaePessoaService: CnaePessoaService,
        protected activatedRoute: ActivatedRoute,
        protected snackBarService: SnackBarService,
        protected dialog: MatDialog,
    ) {
    }

    public get isSomenteVisualizacao(): boolean {
        return this.activatedRoute.snapshot.url[1].path === 'detail';
    }

    public get hasCnaes(): boolean {
        return (
            typeof this.cnaesPessoa !== 'undefined' && this.cnaesPessoa.length > 0
        );
    }

    ngOnInit(): any {
        this.loadCnaesPessoa();
        this.listaCnaes();
    }

    listaCnaes(): void {
        this.cnaeService
            .getAll()
            .subscribe(cnaes => {
                this.cnaes = cnaes;
            });
    }

    public addCnae(): void {
        this.cnaePessoaService
            .create({cnae: this.cnaeFormControl.value, pessoa: this.pessoa})
            .subscribe(
                () => {
                    this.loadCnaesPessoa();
                    this.snackBarService.showSuccess('Cnae adicionado com sucesso!');
                },
                (e) => this.snackBarService.showError('Erro ao adicionar Cnae.', e)
            );
        this.cleanForms();
    }

    public cleanForms(): void {
        this.cnaeFormControl.reset();
    }

    public loadCnaesPessoa(): void {
        this.cnaePessoaService
            .getCnaesPessoa(this.pessoa.id)
            .subscribe(cnaesPessoa => {
                this.cnaesPessoa = cnaesPessoa;
                if (this.cnaesPessoa.length > 0) {
                    this.atividadeFormValidate.emit('VALID');
                } else {
                    this.atividadeFormValidate.emit('NO_VALID');
                }
            });
    }

    public removeCnaeDialog(cnaePessoa: CnaePessoa): void {

        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: {label: cnaePessoa.cnae.descricao}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.removeCnae(cnaePessoa);
            }
        });

    }

    public removeCnae(cnaePessoa: CnaePessoa): void {
        this.cnaePessoaService.removeCnae(cnaePessoa.id).subscribe(
            () => {
                this.snackBarService.showSuccess('Cnae excluído com sucesso!');
                this.loadCnaesPessoa();
            },
            (e) => this.snackBarService.showError('Erro ao excluir cnae.', e)
        );
        this.cleanForms();
    }

}
