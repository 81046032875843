import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {FormControl, Validators} from '@angular/forms';
import {PessoaFisica} from '../../../pages/pessoas-fisicas/pessoa-fisica.model';
import {PessoaFisicaService} from '../../../pages/pessoas-fisicas/pessoa-fisica.service';
import {SnackBarService} from '../../services/snack-bar/snack-bar.service';
import {MatTableDataSource} from '@angular/material/table';

@Component({
    selector: 'app-dialog-selecionar-pf',
    templateUrl: './dialog-selecionar-pf.component.html',
})
export class DialogSelecionarPfComponent implements OnInit {
    cpfControl: FormControl;
    pessoaFisica: PessoaFisica;
    dataSource: MatTableDataSource<PessoaFisica>;

    constructor(private dialogRef: MatDialogRef<DialogSelecionarPfComponent>,
                private pessoaFisicaService: PessoaFisicaService,
                private snackBarService: SnackBarService) {
    }

    ngOnInit(): void {
        this.cpfControl = new FormControl(null, Validators.required);
        this.dataSource = new MatTableDataSource<PessoaFisica>();
    }

    confirm(): void {
        if (this.pessoaFisica) {
            this.dialogRef.close(this.pessoaFisica);
        } else {
            this.snackBarService.showAlert('Pesquise uma pessoa física');
        }
    }

    pesquisar(): void {
        this.pessoaFisica = null;
        this.dataSource.data = [];
        this.cpfControl.markAllAsTouched();
        if (this.cpfControl.valid) {
            this.pessoaFisicaService.getByCpf(this.cpfControl.value).subscribe(result => {
                this.pessoaFisica = result;
                this.dataSource.data = [this.pessoaFisica];
            }, e => this.snackBarService.showError('Erro ao consultar pessoa física', e));
        }
    }
}
