import {Injectable, Injector} from '@angular/core';
import {BaseService} from 'app/main/shared/services/base.service';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {CnaePessoa} from './cnae-pessoa.model';
import {HttpParams} from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class CnaePessoaService extends BaseService<CnaePessoa> {

    constructor(protected injector: Injector) {
        super('/api/cnaes-pessoa', injector, CnaePessoa.prototype, CnaePessoa.fromJson);
    }

    getCnaesPessoa(pessoaId): Observable<CnaePessoa[]> {
        return this.http
            .get(this.urlResource, {params: new HttpParams().set('pessoaId', pessoaId)})
            .pipe(
                map(response => this.jsonToResources(response)),
                catchError(this.handleError.bind(this))
            );
    }

    removeCnae(id): Observable<any> {
        const url = `${this.urlResource}/${id}`;
        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }

}
