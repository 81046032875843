export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'DASHBOARDS':  'Dashboards',
            'SAMPLE'        : {
                'TITLE': 'Sample',
                'BADGE': '2222'
            },
            'ATIVIDADES'        : {
                'TITLE': 'Atividades',
                'BADGE': '11'
            },
            'CONSELHOS'        : {
                'TITLE': 'Conselhos',
                'BADGE': '11'
            },
            'PORTES'        : {
                'TITLE': 'Portes do empreendimento',
                'BADGE': '11'
            }
        }
    }
};
