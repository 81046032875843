import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface ExcludeData {
  id: any;
  label: string;
}

@Component({
  selector: 'app-confirmar-exclusao-dialog',
  templateUrl: './confirmar-exclusao-dialog.component.html',
})
export class ConfirmarExclusaoDialogComponent {
  confirmAction: boolean = false;
  
  constructor(
      public dialogRef: MatDialogRef<ConfirmarExclusaoDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: ExcludeData
  ){}

  onNoClick(): void {
    this.dialogRef.close(false);
  }
  confirm() {
    this.dialogRef.close(true);
  }

}
