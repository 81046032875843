import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {TaxonomiaListComponent} from "./taxonomia-list/taxonomia-list.component";
import {CoreModule} from "../../core/core.module";
import {CoreComponentsModule} from "../../core/components/core-components.module";
import {SharedModule} from "../../shared/shared.module";
import {TaxonomiaListResolver, TaxonomiaResolver} from "./taxonomia.service";
import {TaxonomiaDetailComponent} from "./taxonomia-detail/taxonomia-detail.component";
import {EditarTaxonomiaDialogComponent} from "./dialog-editar-taxonomia/dialog-editar-taxonomia.component";

export const routes: Routes = [
    {
        path: 'new',
        component: TaxonomiaDetailComponent
    },
    {
        path: 'taxonomias',
        component: TaxonomiaListComponent,
        resolve: {
            data: TaxonomiaListResolver
        }
    },
    {
        path: ':id/edit',
        component: TaxonomiaDetailComponent,
        resolve: {
            data: TaxonomiaResolver
        }
    },
    {
        path: ':id/detail',
        component: TaxonomiaDetailComponent,
        resolve: {
            data: TaxonomiaResolver
        }
    }
];

@NgModule({
    declarations: [
        TaxonomiaListComponent,
        TaxonomiaDetailComponent,
        EditarTaxonomiaDialogComponent
    ],
    imports: [
        CoreModule.forRoot(),
        RouterModule.forChild(routes),
        CoreComponentsModule,
        SharedModule
    ],
    entryComponents: [
        TaxonomiaListComponent,
        TaxonomiaDetailComponent,
        EditarTaxonomiaDialogComponent
    ],
    providers: [
        TaxonomiaResolver,
        TaxonomiaListResolver,
    ]
})
export class TaxonomiaModule { }