import { DocumentoTemplate } from './../documentos-template/documento-template.model';
import { BaseModel } from 'app/main/shared/models/base.model';
import { Pessoa } from '../pessoas/pessoa.model';

export class Documento extends BaseModel {
    constructor(
        public id?: number,
        public nome?: string,
        public chave?: string,
        public opcional?: boolean,
        public pessoa?: Pessoa,
        public template?: DocumentoTemplate
    ) {
        super();
    }

    static fromJson(json: any): Documento {
        if (json === undefined || json === null) {
            return null;
        }
        return new Documento(
            json.id,
            json.nome,
            json.chave,
            json.opcional,
            Pessoa.fromJson(json.pessoa),
            DocumentoTemplate.fromJson(json.template)
        );
    }
}
