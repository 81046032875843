import { Cargo } from './cargo.model';
import { Injectable, Injector } from '@angular/core';
import { BaseService } from 'app/main/shared/services/base.service';

@Injectable({
    providedIn: 'root'
})
export class CargoService extends BaseService<Cargo> {
    constructor(protected injector: Injector) {
        super('/api/cargos', injector, Cargo.prototype, Cargo.fromJson);
    }
}
