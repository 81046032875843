import {Injectable, Injector} from "@angular/core";
import {ListResolver} from "../../shared/resolvers/list-resolver";
import {DetailResolver} from "../../shared/resolvers/detail-resolver";
import {RoutingStateService} from "../../core/services/routing-state.service";
import {BaseService} from "../../shared/services/base.service";
import {Taxonomia} from "./taxonomia.model";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {TaxonomiaNomePopularDTO} from "./taxonomia-nome-popular-dto.model";

@Injectable()
export class TaxonomiaListResolver extends ListResolver<Taxonomia> {
    constructor(service: TaxonomiaService) {
        super(service);
    }
}

@Injectable()
export class TaxonomiaResolver extends DetailResolver<Taxonomia> {
    constructor(service: TaxonomiaService, routingStateService: RoutingStateService) {
        super(service, routingStateService);
    }
}

@Injectable({ providedIn: 'root' })
export class TaxonomiaService extends BaseService<Taxonomia> {
    constructor(protected injector: Injector) {
        super(
            '/api/taxonomia',
            injector,
            Taxonomia.prototype,
            Taxonomia.fromJson
        );
    }

    salvarTaxonomiaDTO(taxonomiaDTO: TaxonomiaNomePopularDTO): Observable<Taxonomia> {
        const url = `${this.urlResource}/`;

        return this.http.post(url, taxonomiaDTO)
            .pipe(
                map(Taxonomia.fromJson.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    atualizarTaxonomiaDTO(taxonomiaDTO: TaxonomiaNomePopularDTO): Observable<Taxonomia> {
        const url = `${this.urlResource}/${taxonomiaDTO.taxonomia.id}`;

        return this.http.put(url, taxonomiaDTO)
            .pipe(
                map(Taxonomia.fromJson.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }
}