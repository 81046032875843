import { Injectable, Injector } from '@angular/core';
import { DocumentoTemplate } from './documento-template.model';
import { RoutingStateService } from 'app/main/core/services/routing-state.service';
import { BaseService } from '../../shared/services/base.service';
import { ListResolver } from '../../shared/resolvers/list-resolver';
import { DetailResolver } from '../../shared/resolvers/detail-resolver';

@Injectable()
export class DocumentoTemplateListResolver extends ListResolver<DocumentoTemplate> {
    constructor(service: DocumentoTemplateService) {
        super(service);
    }
}

@Injectable()
export class DocumentoTemplateResolver extends DetailResolver<DocumentoTemplate> {
    constructor(
        service: DocumentoTemplateService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: 'root'
})
export class DocumentoTemplateService extends BaseService<DocumentoTemplate> {
    constructor(protected injector: Injector) {
        super(
            '/api/documentos-template',
            injector,
            DocumentoTemplate.prototype,
            DocumentoTemplate.fromJson
        );
    }
}
