import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {FormControl, Validators} from '@angular/forms';
import {PessoaFisica} from '../../../pages/pessoas-fisicas/pessoa-fisica.model';
import {PessoaFisicaService} from '../../../pages/pessoas-fisicas/pessoa-fisica.service';
import {SnackBarService} from '../../services/snack-bar/snack-bar.service';
import {MatTableDataSource} from '@angular/material/table';
import {PessoaJuridica} from "../../../pages/pessoa-juridica/pessoa-juridica.model";
import {PessoaJuridicaService} from "../../../pages/pessoa-juridica/pessoa-juridica.service";

@Component({
    selector: 'app-dialog-selecionar-empresa',
    templateUrl: './dialog-selecionar-empresa.component.html',
})
export class DialogSelecionarEmpresaComponent implements OnInit {
    cnpjControl: FormControl;
    pessoaJuridica: PessoaJuridica;
    dataSource: MatTableDataSource<PessoaFisica>;

    constructor(private dialogRef: MatDialogRef<DialogSelecionarEmpresaComponent>,
                private pessoaJuridicaService: PessoaJuridicaService,
                private snackBarService: SnackBarService) {
    }

    ngOnInit(): void {
        this.cnpjControl = new FormControl(null, Validators.required);
        this.dataSource = new MatTableDataSource<PessoaFisica>();
    }

    confirm(): void {
        if (this.pessoaJuridica) {
            this.dialogRef.close(this.pessoaJuridica);
        } else {
            this.snackBarService.showAlert('Pesquise uma empresa');
        }
    }

    pesquisar(): void {
        this.pessoaJuridica = null;
        this.dataSource.data = [];
        this.cnpjControl.markAllAsTouched();
        if (this.cnpjControl.valid) {
            this.pessoaJuridicaService.getByCnpj(this.cnpjControl.value).subscribe(result => {
                this.pessoaJuridica = result;
                this.dataSource.data = [this.pessoaJuridica];
            }, e => this.snackBarService.showError('Erro ao consultar empresa', e));
        }
    }
}
