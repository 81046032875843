import {Injectable, Injector} from '@angular/core';
import {environment} from 'environments/environment';
import {HttpClient} from '@angular/common/http';
import {GrupoDocumento} from './grupo-documento.model';
import {Observable} from 'rxjs/internal/Observable';
import {GrupoDocumentoTemplateModel} from './grupo-documento-template.model';
import {catchError, map} from 'rxjs/operators';
import {throwError} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GrupoDocumentoTemplateService {

    private http: HttpClient;
    private urlResource: string = environment.URL_GATEWAY;

    constructor(
        private injector: Injector,
    ) {
        this.http = injector.get(HttpClient);
        this.urlResource += '/api/grupos-documento-template';
    }

    getAllByGrupoDocumento(grupoDocumento: GrupoDocumento): Observable<GrupoDocumentoTemplateModel[]> {
        const url = `${this.urlResource}/grupos-documento/${grupoDocumento.id}`;
        return this.http.get(url).pipe(
            map(this.jsonDataToGrupoDocumentoTemplateModelList),
            catchError(this.handleError)
        );
    }

    addGrupoDocumentoTemplate(grupoDocumentoTemplateModel: GrupoDocumentoTemplateModel): Observable<any> {
        return this.http
            .post(`${this.urlResource}`, grupoDocumentoTemplateModel)
            .pipe(catchError(this.handleError), map(this.jsonDataToGrupoDocumentoTemplateModel));
    }

    removeGrupoDocumentoTemplate(grupoDocumentoTemplateModel: GrupoDocumentoTemplateModel): Observable<any> {
        const url = `${this.urlResource}/grupos-documento/${grupoDocumentoTemplateModel.grupoId}/documentos-template/${grupoDocumentoTemplateModel.templateId}`;
        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError)
        );
    }

    private jsonDataToGrupoDocumentoTemplateModelList(jsonData: any[]): GrupoDocumentoTemplateModel[] {
        const entries: GrupoDocumentoTemplateModel[] = [];

        jsonData.forEach(element => {
            const rt = Object.assign(new GrupoDocumentoTemplateModel(), element);
            entries.push(rt);
        });
        return entries;
    }

    private jsonDataToGrupoDocumentoTemplateModel(jsonData: any): GrupoDocumentoTemplateModel {
        return Object.assign(new GrupoDocumentoTemplateModel(), jsonData);
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }
}
