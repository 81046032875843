import {NgModule} from '@angular/core';
import {GestaoListComponent} from './gestao-list/gestao-list.component';
import {GestaoDetailComponent} from './gestao-detail/gestao-detail.component';
import {RouterModule, Routes} from '@angular/router';
import {GestaoListResolver, GestaoResolver} from './gestao.service';
import {SharedModule} from 'app/main/shared/shared.module';
import {CoreModule} from 'app/main/core/core.module';

export const routes: Routes = [
    {
        path: 'new',
        component: GestaoDetailComponent
    },
    {
        path: 'gestoes',
        component: GestaoListComponent,
        resolve: {
            data: GestaoListResolver
        }
    },
    {
        path: ':id/edit',
        component: GestaoDetailComponent,
        resolve: {
            data: GestaoResolver
        }
    },
    {
        path: ':id/detail',
        component: GestaoDetailComponent,
        resolve: {
            data: GestaoResolver
        }
    }
];

@NgModule({
    declarations: [GestaoListComponent, GestaoDetailComponent],
    imports: [CoreModule, SharedModule, RouterModule.forChild(routes)],
    entryComponents: [GestaoDetailComponent, GestaoListComponent],
    providers: [GestaoResolver, GestaoListResolver]
})
export class GestoesModule {
}
