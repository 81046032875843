import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {CoreModule} from '../../core/core.module';
import {SharedModule} from '../../shared/shared.module';
import {GrupoDocumentoListComponent} from './grupo-documento-list/grupo-documento-list.component';
import {GrupoDocumentoListResolver, GrupoDocumentoResolver} from './grupo-documento.service';
import {GrupoDocumentoDetailComponent} from './grupo-documento-detail/grupo-documento-detail.component';

export const routes: Routes = [
    {
        path: 'new',
        component: GrupoDocumentoDetailComponent
    },
    {
        path: 'grupos-documento',
        component: GrupoDocumentoListComponent,
        resolve: {
            data: GrupoDocumentoListResolver
        }
    },
    {
        path: ':id/edit',
        component: GrupoDocumentoDetailComponent,
        resolve: {
            data: GrupoDocumentoResolver
        }
    },
    {
        path: ':id/detail',
        component: GrupoDocumentoDetailComponent,
        resolve: {
            data: GrupoDocumentoResolver
        }
    }
];

@NgModule({
    declarations: [
        GrupoDocumentoDetailComponent,
        GrupoDocumentoListComponent
    ],
    imports: [CoreModule, SharedModule, RouterModule.forChild(routes)],
    entryComponents: [
        GrupoDocumentoDetailComponent,
        GrupoDocumentoListComponent
    ],
    providers: [GrupoDocumentoResolver, GrupoDocumentoListResolver]
})
export class GruposDocumentoModule {
}
