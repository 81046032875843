import { BaseModel } from 'app/main/shared/models/base.model';
import { OrganizacaoAdministrativa } from '../organizacoes-administrativas/organizacao-administrativa.model';

export class OrganizacaoAdministrativaGrupoCamunda extends BaseModel {
    constructor(
        public id?: number,
        public grupoCamunda?: string,
        public organizacaoAdministrativa?: OrganizacaoAdministrativa
    ) {
        super();
    }

    static fromJson(json: any): OrganizacaoAdministrativaGrupoCamunda {
        if (json === undefined || json === null) {
            return null;
        }
        return new OrganizacaoAdministrativaGrupoCamunda(
            json.id,
            json.grupoCamunda,
            OrganizacaoAdministrativa.fromJson(json.organizacaoAdministrativa),
        );
    }
}
