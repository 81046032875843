import {BaseModel} from 'app/main/shared/models/base.model';
import {BaseEnum} from 'app/main/shared/models/base.enum ';

export class PessoaLicenciamento extends BaseModel {
    constructor(
        public id: number,
        public idCorporativo: number,
        public dataAtualizacao: Date,
        public nome: string,
        public tipo: string,
        public email: string,
        public telefone: string,
        public endereco: string,
        public complemento: string,
        public logradouro: string,
        public bairro: string,
        public numero: string,
        public cpfCnpj: string,
        public rg: string,
        public dataNascimento: Date,
        public sexo: string,
        public profissoes: string,
        public idadeSuperior60Anos: boolean,
        public possuiDeficienciaFisicaMental: boolean,
        public possuiDoencaGrave: boolean,
        public outraDoencaGrave: string,
        // ------------------ DADOS PESSOA JURIDICA BEGIN
        public nomeFantasia: string,
        public inscricaoMunicipal: string,
        public inscricaoEstadual: string,
        public naturezaJuridica: string,
        public porteEmpresa: string
    ) {
        super();
    }

    static tiposPessoa: BaseEnum[] = [
        {valor: 'PF', descricao: 'Pessoa Física'},
        {valor: 'PJ', descricao: 'Pessoa Jurídica'},
    ];

    static sexo: BaseEnum[] = [
        {valor: 'M', descricao: 'Masculino'},
        {valor: 'F', descricao: 'Feminino'},
    ];

    static fromJson(json: any): PessoaLicenciamento {
        if (json === undefined || json === null) {
            return null;
        }
        return new PessoaLicenciamento(
            json.id,
            json.idCorporativo,
            json.dataAtualizacao,
            json.nome,
            json.tipo,
            json.email,
            json.telefone,
            json.endereco,
            json.complemento,
            json.logradouro,
            json.bairro,
            json.numero,
            json.cpfCnpj,
            json.rg,
            json.dataNascimento,
            json.sexo,
            json.profissoes,
            json.idadeSuperior60Anos,
            json.possuiDeficienciaFisicaMental,
            json.possuipossuiDoencaGrave,
            json.outraDoencaGrave,
            json.nomeFantasia,
            json.inscricaoMunicipal,
            json.inscricaoEstadual,
            json.naturezaJuridica,
            json.porteEmpresa
        );
    }

    static fromJsons(json: any): PessoaLicenciamento[] {
        const pessoas: PessoaLicenciamento[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(pessoa => pessoas.push(this.fromJson(pessoa)));
            return pessoas;
        }
    }

}
