import {Component, Injector, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {Gestao} from '../gestao.model';
import {GestaoService} from '../gestao.service';
import {HttpParams} from '@angular/common/http';
import {BaseListComponent} from '../../../shared/components/base-list/base-list.component';
import {Router} from '@angular/router';
import {OrganizacaoAdministrativaTreeComponent} from '../organizacoes-administrativas/organizacao-administrativa-tree/organizacao-administrativa-tree.component';

@Component({
    selector: 'app-gestao-list',
    templateUrl: './gestao-list.component.html',
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class GestaoListComponent extends BaseListComponent<Gestao> {

    constructor(injector: Injector, service: GestaoService) {
        super(
            injector,
            [
                'id',
                'nome',
                'descricao',
                'nomeFantasia',
                'cnpj',
                'buttons'
            ],
            service
        );
        this.router = this.injector.get(Router);
    }

    public static readonly getComponentURI = '/gestoes';

    descricao = '';
    protected router: Router;

    fillFilters(httpParams: HttpParams): any {
        if (httpParams == null) {
            httpParams = new HttpParams();
        }

        httpParams = httpParams.append('descricao', this.descricao);
        return httpParams;
    }

    public openTree(gestao: Gestao): void {
        const uri = OrganizacaoAdministrativaTreeComponent.getUriComponent(gestao.id);
        this.router
            .navigateByUrl(uri, { skipLocationChange: false });
    }
}
