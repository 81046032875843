import {Injectable, Injector} from '@angular/core';
import {Gestao} from './gestao.model';
import {RoutingStateService} from 'app/main/core/services/routing-state.service';
import {BaseService} from 'app/main/shared/services/base.service';
import {ListResolver} from 'app/main/shared/resolvers/list-resolver';
import {DetailResolver} from 'app/main/shared/resolvers/detail-resolver';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class GestaoListResolver extends ListResolver<Gestao> {
    constructor(service: GestaoService) {
        super(service);
    }
}

@Injectable()
export class GestaoResolver extends DetailResolver<Gestao> {
    constructor(
        service: GestaoService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: 'root'
})
export class GestaoService extends BaseService<Gestao> {
    constructor(protected injector: Injector) {
        super(
            '/api/gestoes',
            injector,
            Gestao.prototype,
            Gestao.fromJson
        );
    }
}
