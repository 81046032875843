import { Injectable, Injector } from '@angular/core';
import { BaseService } from 'app/main/shared/services/base.service';
import { OrganizacaoAdministrativaGrupoCamunda } from './organizacao-administrativa-grupo-camunda.model';
import { OrganizacaoAdministrativa } from '../organizacoes-administrativas/organizacao-administrativa.model';
import { of } from 'rxjs/internal/observable/of';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class OrganizacaoAdministrativaGrupoCamundaService extends BaseService<OrganizacaoAdministrativaGrupoCamunda> {
    constructor(protected injector: Injector) {
        super('/api/organizacoes-administrativas-grupos-camunda', injector, OrganizacaoAdministrativaGrupoCamunda.prototype, OrganizacaoAdministrativaGrupoCamunda.fromJson);
    }

    public createByList(
        grupos: OrganizacaoAdministrativaGrupoCamunda[]
    ): Observable<any>{
        const url = `${this.urlResource}/createByList`;
        return this.http
            .post(this.urlResource, grupos)
            .pipe(
                map(this.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    public getAllByOrganizacaoAdministrativa(
        organizacaoAdministrativaId: number
    ): 
        Observable<OrganizacaoAdministrativaGrupoCamunda[]> 
    {
        const url = `${this.urlResource}/organizacao-administrativa/${organizacaoAdministrativaId}`;
        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

}
