import {Injectable, Injector} from '@angular/core';
import {DetailResolver} from 'app/main/shared/resolvers/detail-resolver';
import {ListResolver} from 'app/main/shared/resolvers/list-resolver';
import {RoutingStateService} from 'app/main/core/services/routing-state.service';
import {PessoaJuridica} from 'app/main/pages/pessoa-juridica/pessoa-juridica.model';
import {BaseService} from 'app/main/shared/services/base.service';
import {Observable} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {HttpParams} from '@angular/common/http';
import {Page} from 'app/main/shared/models/page.model';
import {MatPaginator} from '@angular/material/paginator';

@Injectable()
export class PessoaJuridicaListResolver extends ListResolver<PessoaJuridica> {

    constructor(service: PessoaJuridicaService) {
        super(service);
    }
}

@Injectable()
export class PessoaJuridicaResolver extends DetailResolver<PessoaJuridica> {
    constructor(
        service: PessoaJuridicaService,
        routingStateService: RoutingStateService
    ) {
        super(service, routingStateService);
    }
}

@Injectable({
    providedIn: 'root'
})
export class PessoaJuridicaService extends BaseService<PessoaJuridica> {

    constructor(protected injector: Injector) {
        super(
            '/api/pessoas-juridicas',
            injector,
            PessoaJuridica.prototype,
            PessoaJuridica.fromJson
        );
    }

    public listar(nome?: string, page?: MatPaginator): Observable<{} | Page<PessoaJuridica>> {
        const url = `${this.urlResource}`;
        let urlParams: HttpParams = new HttpParams();
        urlParams = urlParams.append('nome', nome);
        urlParams = urlParams.append('page', '' + page.pageIndex);
        urlParams = urlParams.append('page', '' + page.pageSize);
        return this.http
            .get(url, {params: urlParams})
            .pipe(
                map(response => this.jsonToPage(response)),
                catchError(this.handleError.bind(this))
            );
    }

    public listValidadaComUsuario(): Observable<PessoaJuridica[]> {
        const url = `${this.urlResource}`;
        return this.http
            .get(url)
            .pipe(
                map(response => this.jsonToResources(response)),
                catchError(this.handleError.bind(this))
            );
    }

    getByCnpj(cnpj: string): Observable<PessoaJuridica> {
        const url = `${this.urlResource}/cnpj/${cnpj}`;

        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }


}

