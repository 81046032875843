import {BaseModel} from 'app/main/shared/models/base.model';

export class GrupoDocumentoTemplateModel extends BaseModel {
    constructor(
        public grupoId?: number,
        public templateId?: string,
        public opcional?: boolean,
        public descricaoDocumento?: string,
        public tamanhoDocumento?: string,
        public tamanhoPagina?: string,
    ) {
        super();
    }

    static fromJson(json: any): GrupoDocumentoTemplateModel {
        if (json === undefined || json === null) {
            return null;
        }

        return new GrupoDocumentoTemplateModel(
            json.grupoId,
            json.templateId,
            json.opcional,
            json.descricaoDocumento,
            json.tamanhoDocumento,
            json.tamanhoPagina
        );
    }
}
