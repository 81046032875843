import { BaseModel } from 'app/main/shared/models/base.model';
import { ResponsavelTecnico } from '../responsaveis-tecnico/responsavel-tecnico.model';
import { DocumentoTemplate } from '../documentos-template/documento-template.model';

export class DocumentoResponsavelTecnico extends BaseModel {
    constructor(
        public id?: number,
        public titulo?: string,
        public nome?: string,
        public responsavelTecnico?: ResponsavelTecnico,
        public template?: DocumentoTemplate
    ) {
        super();
    }

    static fromJson(json: any): DocumentoResponsavelTecnico {
        if (json === undefined || json === null) {
            return null;
        }
        return new DocumentoResponsavelTecnico(
            json.id,
            json.titulo,
            json.nome,
            ResponsavelTecnico.fromJson(json.responsavelTecnico),
            DocumentoTemplate.fromJson(json.template)
        );
    }
}
