import {BaseModel} from 'app/main/shared/models/base.model';
import {Pessoa} from '../pessoas/pessoa.model';
import {Cnae} from './cnae.model';

export class CnaePessoa extends BaseModel {
    constructor(
        public id?: number,
        public cnae?: Cnae,
        public pessoa?: Pessoa
    ) {
        super();
    }

    static fromJson(json: any): CnaePessoa {
        if (json === undefined || json === null) {
            return null;
        }
        return new CnaePessoa(
            json.id,
            Cnae.fromJson(json.cnae),
            Pessoa.fromJson(json.pessoa),
        );
    }
}
