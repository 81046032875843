import {Injectable, Injector} from '@angular/core';
import {Conselho} from '../models/conselho.model';
import {BaseService} from 'app/main/shared/services/base.service';

@Injectable({
    providedIn: 'root'
})
export class ConselhoService extends BaseService<Conselho> {
    constructor(protected injector: Injector) {
        super(
            '/api/conselhos',
            injector,
            Conselho.prototype,
            Conselho.fromJson
        );
    }
}
