import {BaseModel} from 'app/main/shared/models/base.model';
import {Conselho} from '../../../models/conselho.model';
import {SearchableModel} from '../../select-search/searchable-model';

export class Profissao extends BaseModel implements SearchableModel {

    constructor(
        public id?: number,
        public codigo?: string,
        public descricao?: string,
        public ufOrgao?: string,
        public conselho?: Conselho
    ) {
        super();
    }

    static fromJson(json: any): Profissao {
        if (json === undefined || json === null) {
            return null;
        }
        return new Profissao(
            json.id,
            json.codigo,
            json.descricao,
            json.ufOrgao,
            json.conselho
        );
    }

    static compareWith = (a: Profissao, b: Profissao): boolean => a && b ? a.id === b.id : a === b;

    searchValue(): string {
        return `${this.codigo}, ${this.descricao}`;
    }

    itemLabel(): string {
        return `${this.codigo} - ${this.descricao}`;
    }
}
