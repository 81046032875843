import { Injectable, Injector } from '@angular/core';
import { BaseService } from 'app/main/shared/services/base.service';
import { TipoLogradouro } from './tipo-logradouro.model';

@Injectable({
    providedIn: 'root'
})
export class TipoLogradouroService extends BaseService<TipoLogradouro> {
    constructor(protected injector: Injector) {
        super('/api/tipos-logradouro', injector, TipoLogradouro.prototype, TipoLogradouro.fromJson);
    }
}
