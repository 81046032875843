import {BaseModel} from 'app/main/shared/models/base.model';
import {SearchableModel} from '../../shared/components/select-search/searchable-model';

export class TipoLogradouro extends BaseModel implements SearchableModel {
    constructor(
        public id?: number,
        public codigo?: string,
        public descricao?: string,
        public dataFim?: Date
    ) {
        super();
    }

    static fromJson(json: any): TipoLogradouro {
        if (json === undefined || json === null) {
            return null;
        }
        return new TipoLogradouro(
            json.id,
            json.codigo,
            json.descricao,
            new Date(json.dataFim)
        );
    }

    static compareWith = (a: TipoLogradouro, b: TipoLogradouro): boolean => a && b ? a.id === b.id : a === b;

    searchValue(): string {
        return `${this.codigo}, ${this.descricao}`;
    }

    itemLabel(): string {
        return this.descricao;
    }

}

