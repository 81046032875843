import { Component, ViewEncapsulation, Injector } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { DocumentoTemplate } from '../documento-template.model';
import { DocumentoTemplateService } from '../documento-template.service';
import { HttpParams } from '@angular/common/http';
import { BaseListComponent } from '../../../shared/components/base-list/base-list.component';

@Component({
    selector: 'app-documento-template-list',
    templateUrl: './documento-template-list.component.html',
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class DocumentoTemplateListComponent extends BaseListComponent<DocumentoTemplate> {
    descricao = '';

    constructor(injector: Injector, service: DocumentoTemplateService) {
        super(
            injector,
            [
                'id',
                'descricao',
                'tipoPessoa',
                'tamanhoDocumento',
                'tamanhoPagina',
                'buttons'
            ],
            service
        );
    }

    descricaoFromTipoPessoa(tipoPessoa: string): string {
        return DocumentoTemplate.tiposPessoa.find(t => t.valor === tipoPessoa)
            .descricao;
    }

    descricaoFromTamanhoDocumento(tamanhoDocumento: string): string {
        return DocumentoTemplate.tamanhosDocumento.find(
            t => t.valor === tamanhoDocumento
        ).descricao;
    }

    descricaoFromTamanhoPagina(tamanhoPagina: string): string {
        return DocumentoTemplate.tamanhosPagina.find(
            t => t.valor === tamanhoPagina
        ).descricao;
    }

    fillFilters(httpParams: HttpParams): any {
        if (httpParams == null) {
            httpParams = new HttpParams();
        }

        httpParams = httpParams.append('descricao', this.descricao);
        return httpParams;
    }
}
