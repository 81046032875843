import { Contato } from './../contato/contatos.model';
import {BaseModel} from 'app/main/shared/models/base.model';

export class Pessoa extends BaseModel {
    constructor(
        public ativo?: boolean,
        public contato?: Contato,
        public cadastroCompleto?: boolean,
        public cpfCnpj?: string,
    ) {
        super();
    }

    static fromJson(json: any): Pessoa {
        if (json === undefined || json === null) { return null; }
        let p: Pessoa = new Pessoa();
        p = Object.assign({}, json);
        return p;
    }
}
