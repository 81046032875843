import { Component, ChangeDetectionStrategy, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import { DocumentoTemplate } from '../documento-template.model';
import { fuseAnimations } from '@fuse/animations';
import { DocumentoTemplateService } from '../documento-template.service';
import { BaseDetailComponent } from 'app/main/shared/components/base-detail/base-detail.component';
import { BaseEnum } from 'app/main/shared/models/base.enum ';

@Component({
    selector: 'app-documento-template-detail',
    templateUrl: './documento-template-detail.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations
})
export class DocumentoTemplateDetailComponent extends BaseDetailComponent<
    DocumentoTemplate
> {
    documentoTemplate: DocumentoTemplate;
    constructor(
        protected documentoTemplateService: DocumentoTemplateService,
        protected injector: Injector
    ) {
        super(
            injector,
            new DocumentoTemplate(),
            documentoTemplateService,
            DocumentoTemplate.fromJson
        );
    }

    protected buildResourceForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            descricao: [null, [Validators.required, Validators.maxLength(512)]],
            tipoPessoa: [null, [Validators.required]],
            tamanhoDocumento: [null, [Validators.required]],
            tamanhoPagina: [null, [Validators.required]]
        });
    }

    protected creationPageTitle(): string {
        return 'Cadastro de novo documento template';
    }

    protected editionPageTitle(): string {
        const resourceName = this.resource.descricao || '';
        return 'Editando o documento template: ' + resourceName;
    }

    protected visualizationPageTitle(): string {
        const resourceName = this.resource.descricao || '';
        return 'Visualizando o documento template: ' + resourceName;
    }

    get tiposPessoa(): BaseEnum[] {
        return DocumentoTemplate.tiposPessoa;
    }

    get tamanhosDocumento(): BaseEnum[] {
        return DocumentoTemplate.tamanhosDocumento;
    }

    get tamanhosPagina(): BaseEnum[] {
        return DocumentoTemplate.tamanhosPagina;
    }
}
