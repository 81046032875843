import {BaseModel} from 'app/main/shared/models/base.model';
import { Gestao } from '../gestao.model';
import { OrganizacaoAdministrativa } from '../organizacoes-administrativas/organizacao-administrativa.model';

export class GestaoOrganizacaoAdministrativa extends BaseModel {
    constructor(
        public id?: number,
        public gestao?: Gestao,
        public organizacaoAdministrativa?: OrganizacaoAdministrativa
    ) {
        super();
    }

    static fromJson(json: any): GestaoOrganizacaoAdministrativa {
        if (json === undefined || json === null) {
            return null;
        }
        return new GestaoOrganizacaoAdministrativa(
            json.id,
            Gestao.fromJson(json.gestao),
            OrganizacaoAdministrativa.fromJson(json.organizacaoAdministrativa),
        );
    }
}
