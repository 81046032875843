import { Pais } from './pais.model';
import { Injectable, Injector } from '@angular/core';
import { BaseService } from 'app/main/shared/services/base.service';

@Injectable({
    providedIn: 'root'
})
export class PaisService extends BaseService<Pais> {
    constructor(protected injector: Injector) {
        super('/api/paises', injector, Pais.prototype, Pais.fromJson);
    }
}
