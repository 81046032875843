import {BaseModel} from "../../shared/models/base.model";
import {Taxonomia} from "./taxonomia.model";
import {NomePopular} from "./nome-popular.model";

export class TaxonomiaNomePopularDTO  extends BaseModel {
    constructor(
        public taxonomia?: Taxonomia,
        public nomePopular?: NomePopular[]) {
        super();
    }
}