import {BaseModel} from "../../shared/models/base.model";

export class Taxonomia extends BaseModel {
    constructor(
        public id?: number,
        public nomeCientifico?: string,
        public nomeCienfificoFormatado?: string,
        public situacaoCancelado?: string,
        public usoSema?: boolean) {
        super();
    }

    static fromJson(json: any): Taxonomia {
        json = json || {};
        return new Taxonomia(
            json.id,
            json.nomeCientifico,
            json.nomeCienfificoFormatado,
            json.situacaoCancelado,
            json.usoSema
        );
    }
}