import {Injectable, Injector} from "@angular/core";
import {BaseService} from "../../shared/services/base.service";
import {NomePopular} from "./nome-popular.model";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {HttpParams} from "@angular/common/http";

@Injectable({ providedIn: 'root' })
export class NomePopularService extends BaseService<NomePopular> {
    constructor(protected injector: Injector) {
        super(
            '/api/nome-popular',
            injector,
            NomePopular.prototype,
            NomePopular.fromJson
        );
    }

    buscarNomePopularPorIdTaxonomia(idTaxonomia: number): Observable<NomePopular[]> {
        const url = `${this.urlResource}/`;

        return this.http.get(url,{params: new HttpParams().set('seqTaxonomia', idTaxonomia.toString())})
            .pipe(
                map(response => response),
                catchError(this.handleError)
            );
    }
}