import {ChangeDetectionStrategy, Component, Injector, OnInit, ViewEncapsulation} from "@angular/core";
import {fuseAnimations} from "../../../../../@fuse/animations";
import {BaseDetailComponent} from "../../../shared/components/base-detail/base-detail.component";
import {Taxonomia} from "../taxonomia.model";
import {TaxonomiaService} from "../taxonomia.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TaxonomiaNomePopularDTO} from "../taxonomia-nome-popular-dto.model";
import {NomePopular} from "../nome-popular.model";
import {MatTableDataSource} from "@angular/material/table";
import {NomePopularService} from "../nome-popular-service";
import {BaseModel} from "../../../shared/models/base.model";
import {ConfirmarExclusaoDialogComponent} from "../../../shared/components/confirmar-exclusao-dialog/confirmar-exclusao-dialog.component";
import {EditarTaxonomiaDialogComponent} from "../dialog-editar-taxonomia/dialog-editar-taxonomia.component";

@Component({
    selector: 'app-taxonomia-detail',
    templateUrl: './taxonomia-detail.component.html',
    styleUrls: ['./taxonomia-detail.component.scss'],
    animations: fuseAnimations,
    changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.None
})

export class TaxonomiaDetailComponent extends BaseDetailComponent<Taxonomia> implements OnInit {

    displayedColumns: string[] = ['nomePopular', 'ativo'];
    nomePopularList: any[] = [];
    iteradorNomePopular: number = 0;
    dataSource: MatTableDataSource<any>;
    isCancelado: boolean = false;
    formNomePopular: FormGroup;

    constructor(
        protected injector: Injector,
        protected taxonomiaService: TaxonomiaService,
        protected nomePopularService: NomePopularService,
    ) {
        super(
            injector,
            new Taxonomia(),
            taxonomiaService,
            Taxonomia.fromJson
        );
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.buildFormNomePopular();
        //Alterando situação cancelado caso esteja no modo edição ou visualização e carregando nomes populares salvos.
        if(this.resource.id){
            this.carregarNomePopular();
            this.atribuirCancelado(this.resource.situacaoCancelado);
        }
    }

    public carregarNomePopular(){
        this.iteradorNomePopular = this.nomePopularList.length;
        this.nomePopularService.buscarNomePopularPorIdTaxonomia(this.resource.id).subscribe( retorno => {
            retorno.forEach(i => {
                this.nomePopularList.push(new NomePopularTable(i.id, i.descNomePopular, i.situacaoCancelado, i.taxonomia,
                    this.iterarIdNomePopular()))
            })
            this.dataSource = new MatTableDataSource(this.nomePopularList);
        });
    }

    //Id temporario atribuido aos elementos da lista de nome popular, para manipulação dos mesmos.
    public iterarIdNomePopular(): number{
        this.iteradorNomePopular += 1;
        return this.iteradorNomePopular;
    }

    public atribuirCancelado(cancelado: string){
        if(cancelado == "N"){
            this.isCancelado = false;
        }else {
            this.isCancelado = true;
        }
    }

    //dialog para edição dos nomes populares de uma taxonomia
    editarDialog(nomePopular: any): void {
        const dialogRef = this.dialog.open(EditarTaxonomiaDialogComponent, {
            width: '500px',
            data: {
                label: nomePopular
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != null) {
                nomePopular.descNomePopular = result;
            }
        });
    }

    public checkBoxCancelado(event: any){
        this.isCancelado = event;
    }

    public buildFormNomePopular(): void {
        this.formNomePopular = this.formBuilder.group({
            id: [null],
            //id para manipular elementos que ainda não foram salvos no banco
            idTemporario: [null],
            descNomePopular: [null, [Validators.required]],
            idioma: [null],
            situacaoCancelado: [null],
        })
    }

    protected buildResourceForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            nomeCientifico: [null, [Validators.required]],
            nomeCientificoFormatado: [null],
            usoSema: [false],
        });
    }

    public adicionarNomePopular(){
        if(this.formNomePopular.valid){
            this.nomePopularList.push(new NomePopularTable(null, this.formNomePopular.controls.descNomePopular.value,
                null, null, this.iterarIdNomePopular()))
            this.dataSource = new MatTableDataSource(this.nomePopularList);
        }else{
            this.snackBarService.showAlert('Insira um nome popular!');
        }
    }

    //Remove um nome popular da lista, caso seja um nome popular já salvo na base de dados
    // é chamado o serviço da API para deleta o mesmo.
    public removerNomePopular(nomePopular: any){
        if(nomePopular.id != null){
            const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
                width: '300px',
                data: {
                    label: nomePopular.descNomePopular
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (!!result) {
                    this.nomePopularService.delete(nomePopular.id).subscribe(() => {
                        this.nomePopularList = this.nomePopularList.filter( i => {
                            return i.idTemporario != nomePopular.idTemporario;
                        })
                        this.dataSource = new MatTableDataSource(this.nomePopularList);
                        this.snackBarService.showSuccess('Solicitação processada com sucesso!');
                    }, error => {
                        this.actionsForError(error);
                    });
                }
            });
        }else{
            this.nomePopularList = this.nomePopularList.filter( i => {
                return i.idTemporario != nomePopular.idTemporario;
            })
            this.dataSource = new MatTableDataSource(this.nomePopularList);
        }
    }

    public salvarTaxonomia(){
        if(this.form.valid){
            const taxonomiaDto = this.montarDtoTaxonomia();
            if(this.resource.id != null){
                this.taxonomiaService.atualizarTaxonomiaDTO(taxonomiaDto).subscribe( resposta => {
                    this.actionsForSuccess(resposta);
                }, error => {
                    this.actionsForError(error)
                })
            }else{
                this.taxonomiaService.salvarTaxonomiaDTO(taxonomiaDto).subscribe(resposta => {
                    this.actionsForSuccess(resposta);
                }, error => {
                    this.actionsForError(error)
                })
            }
        }else {
            this.snackBarService.showAlert('Insira um nome para a taxonomia!');
        }
    }

    public montarDtoTaxonomia(): TaxonomiaNomePopularDTO{
        const taxonomiaDto = new TaxonomiaNomePopularDTO();
        taxonomiaDto.taxonomia = new Taxonomia();
        if(this.resource){
            taxonomiaDto.taxonomia.id = this.resource.id;
        }
        taxonomiaDto.nomePopular = [];
        taxonomiaDto.taxonomia.nomeCientifico = this.form.controls.nomeCientifico.value;
        taxonomiaDto.taxonomia.nomeCienfificoFormatado = this.form.controls.nomeCientifico.value;
        taxonomiaDto.taxonomia.situacaoCancelado = this.isCancelado == false ? "N" : "S";
        taxonomiaDto.taxonomia.usoSema = this.form.controls.usoSema.value;
        if(this.nomePopularList.length != 0){
            this.nomePopularList.forEach(i => {
                if(i.id != null){
                    taxonomiaDto.nomePopular.push(i);
                }else{
                    taxonomiaDto.nomePopular.push(new NomePopular(i.id, i.descNomePopular, "N", null));
                }
            })
        }
        return taxonomiaDto;
    }

    get isSomenteCriacao(): boolean {
        return this.currentAction === 'new';
    }

    get isSomenteEdicao(): boolean {
        return this.currentAction === 'edit';
    }

    protected creationPageTitle(): string {
        return 'Cadastro de nova Taxonomia';
    }

    protected editionPageTitle(): string {
        return `Editando a Taxonomia: ${this.resource.nomeCientifico}`;
    }

    protected visualizationPageTitle(): string {
        return `Visualizando a Taxonomia: ${this.resource.nomeCientifico}`;
    }
}

export class NomePopularTable extends BaseModel {
    constructor(
        public id?: number,
        public descNomePopular?: string,
        public situacaoCancelado?: string,
        public taxonomia?: Taxonomia,
        public idTemporario?: number) {
        super();
    }
}