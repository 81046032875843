import {Component, Injector, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {HttpParams} from '@angular/common/http';
import {BaseListComponent} from '../../../shared/components/base-list/base-list.component';
import {GrupoDocumento} from '../grupo-documento.model';
import {GrupoDocumentoService} from '../grupo-documento.service';

@Component({
    selector: 'app-grupo-documento-list',
    templateUrl: './grupo-documento-list.component.html',
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class GrupoDocumentoListComponent extends BaseListComponent<GrupoDocumento> {

    descricao = '';

    constructor(injector: Injector, service: GrupoDocumentoService) {
        super(
            injector,
            [
                'id',
                'nome',
                'descricao',
                'buttons'
            ],
            service
        );
    }

    fillFilters(httpParams: HttpParams): any {
        if (httpParams == null) {
            httpParams = new HttpParams();
        }

        httpParams = httpParams.append('descricao', this.descricao);
        return httpParams;
    }
}
