import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'error-401',
    templateUrl: 'error-401.component.html',
    styleUrls: ['../error.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class Error401Component {
    detalhes: string;

    constructor(private router: Router) {
        //this.detalhes = this.router.getCurrentNavigation().extras.state.message;
        //if (this.detalhes) {
        //    this.detalhes = this.detalhes.replace('Você não  para acessar este recurso. ', '');
        //}
    }
}
