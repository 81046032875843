import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Usuario } from '../models/usuario.model';
import { environment } from '../../../../environments/environment';
import { AuthServerProvider } from "app/main/core/services/auth-provider.service";


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<Usuario>;
    public currentUser: Observable<Usuario>;

    constructor(private http: HttpClient,
            private authServerProvider: AuthServerProvider ) {
        this.currentUserSubject = new BehaviorSubject<Usuario>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): Usuario {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string): any {
        return this.http.post<any>(`${environment.URL_PORTAL_API}/api/auth`, 
        { login: username, senha: password })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                }
                // return user;
            }),
            catchError(this.handleError.bind(this)));
    }

    logout(): any {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }

    getUser(): Observable<Usuario> {
        return this.http.get(`${environment.URL_PORTAL_API}/api/auth/me`)
            .pipe(map(user => Object.assign(new Usuario(), user)),
            catchError(this.handleError.bind(this)));
    }
    
    loginWithToken(jwt): Observable<Usuario> {
        if (jwt) {
            let userData: any = {  token: jwt  };
            this.currentUserSubject.next(userData);
            this.authServerProvider.tokenValue = userData.token;
            return this.getUser().pipe(map((user) => {
                    if (user && user.id) {
                        user.token = userData.token;
                        localStorage.setItem('currentUser', JSON.stringify(user));
                        this.authServerProvider.tokenValue = user.token;
                        this.currentUserSubject.next(user);
                        return user;
                    }
                })
            );
        }  
        return  of(null);
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }
}
