import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'dialog-editar-taxonomia',
    templateUrl: './dialog-editar-taxonomia.component.html',
})
export class EditarTaxonomiaDialogComponent {
    confirmAction: boolean = false;
    novoNome = '';

    constructor(
        public dialogRef: MatDialogRef<EditarTaxonomiaDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ){
        console.log("data", data);
    }

    onNoClick(): void {
        this.dialogRef.close(null);
    }
    confirm() {
        this.dialogRef.close(this.novoNome);
    }

}
