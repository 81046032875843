import {BaseModel} from "../../shared/models/base.model";
import {Taxonomia} from "./taxonomia.model";

export class NomePopular extends BaseModel {
    constructor(
        public id?: number,
        public descNomePopular?: string,
        public situacaoCancelado?: string,
        public taxonomia?: Taxonomia) {
        super();
    }

    static fromJson(json: any): NomePopular {
        json = json || {};
        let nomePopular: NomePopular = new NomePopular();
        nomePopular = Object.assign({}, json);

        return nomePopular;
    }
}