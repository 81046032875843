import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SharedModule} from 'app/main/shared/shared.module';
import {CoreModule} from 'app/main/core/core.module';
import {OrganizacaoAdministrativaTreeComponent} from './organizacao-administrativa-tree/organizacao-administrativa-tree.component';
import {OrganizacaoAdmnistrativaResolver} from './organizacao-administrativa.service';
import {MatSidenavModule, MatTreeModule} from '@angular/material';
import {OrganizacaoAdministrativaDetailComponent} from './organizacao-administrativa-detail/organizacao-administrativa-detail.component';
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatTooltipModule} from "@angular/material/tooltip";

export const routes: Routes = [
    {
        path: 'gestao/:id/tree',
        component: OrganizacaoAdministrativaTreeComponent,
        resolve: {
            data: OrganizacaoAdmnistrativaResolver
        }
    }
];

@NgModule({
    declarations: [OrganizacaoAdministrativaTreeComponent, OrganizacaoAdministrativaDetailComponent],
    imports: [MatSidenavModule, MatTreeModule, CoreModule, SharedModule, RouterModule.forChild(routes), MatAutocompleteModule, MatTooltipModule],
    entryComponents: [OrganizacaoAdministrativaTreeComponent],
    providers: [OrganizacaoAdmnistrativaResolver]
})
export class OrganizacoesAdministrativasModule {
}
