import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoreModule } from '../../core/core.module';
import { DocumentoTemplateListComponent } from './documento-template-list/documento-template-list.component';
import { DocumentoTemplateDetailComponent } from './documento-template-detail/documento-template-detail.component';
import {
    DocumentoTemplateResolver,
    DocumentoTemplateListResolver
} from './documento-template.service';
import { SharedModule } from 'app/main/shared/shared.module';

export const routes: Routes = [
    {
        path: 'new',
        component: DocumentoTemplateDetailComponent
    },
    {
        path: 'documentos-template',
        component: DocumentoTemplateListComponent,
        resolve: {
            data: DocumentoTemplateListResolver
        }
    },
    {
        path: ':id/edit',
        component: DocumentoTemplateDetailComponent,
        resolve: {
            data: DocumentoTemplateResolver
        }
    },
    {
        path: ':id/detail',
        component: DocumentoTemplateDetailComponent,
        resolve: {
            data: DocumentoTemplateResolver
        }
    }
];

@NgModule({
    declarations: [
        DocumentoTemplateListComponent,
        DocumentoTemplateDetailComponent
    ],
    imports: [CoreModule, SharedModule, RouterModule.forChild(routes)],
    entryComponents: [
        DocumentoTemplateDetailComponent,
        DocumentoTemplateListComponent
    ],
    providers: [DocumentoTemplateResolver, DocumentoTemplateListResolver]
})
export class DocumentosTemplateModule {}
