import {BaseModel} from 'app/main/shared/models/base.model';
import {Role} from './role';

export class Usuario extends BaseModel {
    constructor(
        public id?: number,
        public login?: string,
        public nome?: string,
        public email?: string,
        public servidor?: boolean,
        public role?: Role,
        public token?: string
    ) {
        super();
    }

    static fromJson(json: any): Usuario {
        if (json === undefined || json === null) { return null; }
        let us: Usuario = new Usuario();
        us = Object.assign({}, json);
        us.login = us.login ? us.login.match(/\d+/g).join('') : null;
        return us;
    }
}
