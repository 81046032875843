import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DocumentoResponsavelTecnicoService } from 'app/main/pages/documentos-responsaveis-tecnicos/documento-responsavel-tecnico.service';
import { SnackBarService } from '../../services/snack-bar/snack-bar.service';
import { Documento } from './../../../pages/documentos/documento.model';

@Component({
    selector: 'app-documento-responsavel-tecnico-dialog',
    templateUrl: './documento-responsavel-tecnico-dialog.component.html'
})
export class DocumentoResponsavelTecnicoDialogComponent implements OnInit {
    form: FormGroup;
    isSomenteVisualizacao = false;
    file;
    isValid = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) protected data: Documento,
        public dialogRef: MatDialogRef<DocumentoResponsavelTecnicoDialogComponent>,
        public documentoService: DocumentoResponsavelTecnicoService,
        public formBuilder: FormBuilder,
        protected snackBarService: SnackBarService
    ) {}

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            nome: [
                this.data.template.descricao,
                [Validators.required, Validators.maxLength(255)]
            ],
            arquivo: [null, [Validators.required]]
        });
    }

    onFileSelect(event): void {
        if (!event.target.files[0].size) {
            this.snackBarService.showAlert('Arquivo com tamanho inválido.');
            document.getElementById('input-arquivo')['value'] = null;
            return;
        }
        if (event.target.files.length === 1) {
            const file = event.target.files[0];
            this.file = file;
            this.form.get('arquivo').setValue(file);
        } else {
            this.form.get('arquivo').reset();
        }
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    confirm(): void {
        const multipartFile = this.form.get('arquivo').value;
        this.validarPdf();
        if (this.isValid) {
            (this.data.id === null
                    ? this.documentoService.createMultipart(this.data, multipartFile)
                    : this.documentoService.updateMultipart(this.data, multipartFile)
            ).subscribe(
                () => {
                    this.dialogRef.close(true);
                },
                (e) => {
                    this.snackBarService.showError(
                        'Não foi possível salvar o registro.', e);
                }
            );
        }
        this.isValid = true;
    }

    get nome(): string {
        return this.data.template.descricao;
    }

    get titleDialog(): string {
        return this.nome === '' ? 'Anexar ' : 'Alterar ';
    }

    public async validarPdf(): Promise<void> {
        if (this.file.type !== 'application/pdf') {
            this.snackBarService.showAlert(
                'Valido somente arquivo PDF.');
            this.isValid = false;
        } else {
            await this.validarTamanhoArquivo();
        }
    }
    private async validarTamanhoArquivo(): Promise<void> {

        // Verifica se o tamnho está em MB ou  KB
        if (this.file.size > 1024 * 1024) {
            const tamanhoMega = (Math.round(this.file.size * 100 / (1024 * 1024)) / 100);
            let contador = 1;
            ['UM_MEGA', 'DOIS_MEGA', 'TRES_MEGA', 'QUATRO_MEGA', 'CINCO_MEGA', 'DEZ_MEGA', 'QUINZE_MEGA'].forEach(tamanho => {
                if (tamanhoMega > contador) {
                    this.isValid = false;
                    this.mensagemArquivo(1);
                }
                contador = +1;
            });
        } else {
            if (this.data.template.tamanhoDocumento === 'TREZENTOS_KB') {
                const tamanhoKb = (Math.round(this.file.size * 100 / 1024) / 100);
                if (tamanhoKb > 300) {
                    this.mensagemArquivo(1);
                }
            }
        }
        // execulta validação tamanho pagina
        if (this.isValid) {
            await this.validarTamanhoPagina();
        }
    }
    public async validarTamanhoPagina(): Promise<void> {
        let qtdPaginas = 0;
        const reader = new FileReader();
        reader.readAsBinaryString(this.file);
        qtdPaginas = await new Promise((resolve, reject) => {
            reader.onload = () => {
                if (typeof reader.result === 'string') {
                    resolve(reader.result.match(/\/Type[\s]*\/Page[^s]/g).length);
                }
            };
        });
        // se o tamanho do arquivo  for MB
        if (this.file.size > 1024 * 1024) {
            const tamanhoArquivoMega = (Math.round(this.file.size * 100 / (1024 * 1024)) / 100);
            const tamanhoMedioPagina = tamanhoArquivoMega / qtdPaginas;
            if (tamanhoMedioPagina > 1024 * 1024) {
                const tamanhoMedioPaginaMega = (Math.round(tamanhoMedioPagina * 100 / (1024 * 1024)) / 100);
                let contador = 1;
                ['UM_MEGA', 'DOIS_MEGA', 'TRES_MEGA', 'QUATRO_MEGA', 'CINCO_MEGA'].forEach(tamanho => {
                    if (tamanhoMedioPaginaMega > 1) {
                        this.isValid = false;
                        this.mensagemArquivo(0);
                    }
                    contador = +1;
                });
            } else {
                this.validarTamanhoEmKb(qtdPaginas);
            }
        }
        // se o tamanho do arquivo  for Kb
        else {
            this.validarTamanhoEmKb(qtdPaginas);
        }
    }

    public validarTamanhoEmKb(qtdPaginas): void {
        if (this.data.template.tamanhoPagina === 'TREZENTOS_KB') {
            const kb = (Math.round(this.file * 100 / 1024) / 100);
            const tamanhoMedioPagina = kb / qtdPaginas;
            if (tamanhoMedioPagina > 300) {
                this.isValid = false;
                this.mensagemArquivo(0);
            }
        }
    }
    public mensagemArquivo(tipoMensagem: number): void {
        let textoMensagem: string;
        tipoMensagem === 1 ? textoMensagem = 'Tamanho do arquivo  ultrapassou o limite máximo.' : textoMensagem = 'Tamanho médio das paginas ultrapassou o limite máximo.';
        this.snackBarService.showAlert(
            textoMensagem);
    }
}
