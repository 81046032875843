import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {BaseDetailComponent} from 'app/main/shared/components/base-detail/base-detail.component';
import {Gestao} from '../gestao.model';
import {AbstractControl, Validators} from '@angular/forms';
import {GestaoService} from '../gestao.service';
import {fuseAnimations} from '@fuse/animations';
import {PessoaJuridicaService} from '../../pessoa-juridica/pessoa-juridica.service';
import {PessoaJuridica} from '../../pessoa-juridica/pessoa-juridica.model';
import {DialogSelecionarEmpresaComponent} from "../../../shared/components/dialog-selecionar-empresa/dialog-selecionar-empresa.component";
import {MaskPipe} from "ngx-mask";

@Component({
    selector: 'app-gestao-detail',
    templateUrl: './gestao-detail.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations
})
export class GestaoDetailComponent extends BaseDetailComponent<Gestao> implements OnInit {

    gestao: Gestao;
    pessoasJuridicas: PessoaJuridica[] = [];

    constructor(
        protected gestaoService: GestaoService,
        protected injector: Injector,
        protected pessoaJuridicaService: PessoaJuridicaService,
        private maskPipe: MaskPipe
    ) {
        super(injector, new Gestao(), gestaoService, Gestao.fromJson);
    }

    ngOnInit(): any {
        super.ngOnInit();

        this.pessoaJuridicaService.getAll().subscribe(pessoasJuridicas => {
            this.pessoasJuridicas = pessoasJuridicas;

            if (this.resource.pessoaJuridica) {
                this.form.controls.pessoaJuridica.patchValue(this.resource.pessoaJuridica);
            }
        });
    }

    protected buildResourceForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            descricao: [null, [Validators.required, Validators.maxLength(200)]],
            nome: [null, [Validators.required, Validators.maxLength(200)]],
            pessoaJuridica: [null, Validators.required]
        });
    }

    protected creationPageTitle(): string {
        return 'Cadastro de nova gestão';
    }

    protected editionPageTitle(): string {
        const resourceName = this.resource.descricao || '';
        return 'Editando a gestão: ' + resourceName;
    }

    protected visualizationPageTitle(): string {
        const resourceName = this.resource.descricao || '';
        return 'Visualizando a gestão: ' + resourceName;
    }

    editarEmpresa(control: AbstractControl): void {
        const dialogRef = this.dialog.open(DialogSelecionarEmpresaComponent);
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                control.setValue(result);
                control.markAsDirty();
                control.updateValueAndValidity();
            }
        });
    }

    formatPJ(pj: PessoaJuridica): string {
        return pj ? `${pj.nomeFantasia} - ${this.maskPipe.transform(pj.cnpj, '00.000.000/0000-00')}` : '';
    }
}
