import {BaseModel} from 'app/main/shared/models/base.model';

export class GrupoDocumento extends BaseModel {
    constructor(
        public id?: number,
        public nome?: string,
        public descricao?: string,
        public dataInicio?: Date,
        public dataFim?: Date,
    ) {
        super();
    }

    static fromJson(json: any): GrupoDocumento {
        if (json === undefined || json === null) {
            return null;
        }

        return new GrupoDocumento(
            json.id,
            json.nome,
            json.descricao,
            json.dataInicio,
            json.dataFim,
        );
    }
}
