import { Injectable, Injector } from '@angular/core';
import { DocumentoResponsavelTecnico } from './documento-responsavel-tecnico.model';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ResponsavelTecnico } from '../responsaveis-tecnico/responsavel-tecnico.model';
import { BaseService } from 'app/main/shared/services/base.service';

@Injectable({
    providedIn: 'root'
})
export class DocumentoResponsavelTecnicoService extends BaseService<
    DocumentoResponsavelTecnico
> {
    constructor(protected injector: Injector) {
        super(
            '/api/documentos-responsaveis-tecnicos',
            injector,
            DocumentoResponsavelTecnico.prototype,
            DocumentoResponsavelTecnico.fromJson
        );
    }

    getByResponsavelId(
        responsavelTecnico: ResponsavelTecnico
    ): Observable<DocumentoResponsavelTecnico[]> {
        const url = `${this.urlResource}/responsavel/${responsavelTecnico.id}`;
        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    createMultipart(
        documentoResponsavelTecnico: DocumentoResponsavelTecnico,
        multipartFile: Blob
    ): Observable<DocumentoResponsavelTecnico> {
        return this.http
            .post(
                this.urlResource,
                this.createFormData(documentoResponsavelTecnico, multipartFile)
            )
            .pipe(
                map(DocumentoResponsavelTecnico.fromJson.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    updateMultipart(
        documentoResponsavelTecnico: DocumentoResponsavelTecnico,
        multipartFile: Blob
    ): Observable<DocumentoResponsavelTecnico> {
        const url = `${this.urlResource}/${documentoResponsavelTecnico.id}`;
        return this.http
            .put(
                url,
                this.createFormData(documentoResponsavelTecnico, multipartFile)
            )
            .pipe(
                map(() => documentoResponsavelTecnico),
                catchError(this.handleError.bind(this))
            );
    }

    private createFormData(
        documento: DocumentoResponsavelTecnico,
        multipartFile: Blob
    ): FormData {
        const formData = new FormData();
        formData.append('multipartFile', multipartFile);
        const documentoParam = new Blob([JSON.stringify(documento)], {
            type: 'application/json'
        });
        formData.append('documentoResponsavelTecnico', documentoParam);
        return formData;
    }
}
