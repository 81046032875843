import { Injectable, Injector } from '@angular/core';
import { Pessoa } from './pessoa.model';
import { BaseService } from 'app/main/shared/services/base.service';
import {Observable} from 'rxjs';
import {PessoaFisica} from '../pessoas-fisicas/pessoa-fisica.model';
import {catchError, map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PessoaService extends BaseService<Pessoa> {
    constructor(protected injector: Injector) {
        super('/api/pessoas', injector, Pessoa.prototype, Pessoa.fromJson);
    }

     atualizarStatusCadastro(pessoa: Pessoa): Observable<PessoaFisica> {
        const url = `${this.urlResource}/completar-cadastro/${pessoa.id}`;

        return this.http
            .put(url, pessoa)
            .pipe(
                map(() => null),
                catchError(this.handleError)
            );
    }

    tornarCadastroIncompleto(pessoa: Pessoa): Observable<any> {
        const url = `${this.urlResource}/tornar-cadastro-incompleto/${pessoa.id}`;

        return this.http
            .put(url, pessoa)
            .pipe(
                map(() => null),
                catchError(this.handleError)
            );
    }
}
